<div class="flex flex-row justify-content-between pt-3">
  <h3 class="text-lg ms-2  fw-500 text-muted-8">{{heading}}</h3>
  <input type="text" placeholder="Search" class="me-2 p-2 mb-2 text-center search-box" #filterText (keyup)="filter(filterText.value)">
</div>



<div class="wrapper-panel  mx-2 mt-2" *ngIf="filterDataSource?.length>0">
  @for (item of filterDataSource; track $index) {
  <div class="item-panel  mb-3">
  <mat-card>
    @if(item.imageUrl){
      <img [src]="item.imageUrl" 
       class="trueself-template-view-image">
    }
    @else{
      <img src="assets/images/profile.png"  
      class="trueself-template-view-image">
    }
    <div class="d-flex align-items-center  justify-content-between mt-2">
  
      <h4 [matTooltip]="item.name" class="text-muted-8 card-title mb-1" [matTooltipPosition]="'below'">{{(item.name?.length > 30 ? item.name.substring(0,
       30) + '...': item.name)}}</h4>
      
      
      <div *ngIf="!showCopyTemplate" class="d-flex justify-content-end">
        <button mat-icon-button [matMenuTriggerFor]="menu" >
          <mat-icon>more_vert</mat-icon>
        </button>
      
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="handleUpdate(item)">
            <mat-icon>edit_note</mat-icon>
            <span>Update</span>
          </button>
          <button mat-menu-item (click)="handleChange(item)">
            <mat-icon>settings</mat-icon>
            <span>Change Image</span>
          </button>
          <button mat-menu-item (click)="handleDelete(item)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
       </div>
       <div *ngIf="showCopyTemplate" class="d-flex justify-content-end">
        <button type="button" class="icon-btn me-2">
          <i class="fa-solid fa-copy"></i> <sup *ngIf="item?.copied?.length > 0">({{item?.copied?.length}})</sup>
        </button>
        <button mat-button class="primary-mat-btn"
         (click)="copyTemplate(item)">Clone Template</button>
      </div>
  </mat-card>
  </div>
  }
  </div>


<ng-container *ngIf="dataSource?.length === 0">
  <p class="flex flex-row justify-center">No qoute exits.
  </p>
</ng-container>