import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'app/layout/models/user/user.service';
import { TrueSelfThinking } from 'app/modules/my-life/true-self/models/true-self-board';
import { TrueSelfBoardDataService } from 'app/modules/my-life/true-self/services/true-self-board-data.service';
import { ConfirmDialogComponent } from 'app/shared/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs';
import { TtLinkCardComponent } from '../../../modules/my-life/true-self/trues-self-template/components/tt-link-card/tt-link-card.component';
import { TtShowCardComponent } from '../../../modules/my-life/true-self/trues-self-template/components/tt-show-card/tt-show-card.component';
import { UpdateTrueSelfTemplateListDialogComponent } from '../../../modules/my-life/true-self/trues-self-template/components/update-true-self-template-list-dialog/update-true-self-template-list-dialog.component';
import { TrueSelfTemplateDataService } from '../../../modules/my-life/true-self/services/true-self-template-data.service';
import { TrueSelfTemplate, TrueSelfTemplateThinking, TrueSelfTemplateType } from 'app/modules/my-life/true-self/trues-self-template/models/true-self-template';
import { blobKey } from 'app/contants';
import { TrueSelfTemplateTypeImageDialogComponent } from 'app/modules/my-life/true-self/trues-self-template/components/true-self-template-type-image-dialog/true-self-template-type-image-dialog.component';

@Component({
  selector: 'owx-tt-thinking',
  templateUrl: './tt-thinking.component.html',
  styleUrls: ['./tt-thinking.component.scss']
})
export class TtThinkingComponent implements OnInit {
  @Input() trueSelfTemplates: TrueSelfTemplate[];
  @Input() showCopyTemplate: boolean = false;
  @Input() heading:any;
  trueSelfTemplateThinking: TrueSelfTemplateThinking[] = [];
  displayedColumns: string[] = ['picture', 'name', 'action'];
  dataSource: TrueSelfTemplateThinking[] = [];
  currentSelectedTemplate: TrueSelfTemplate;
  filterDataSource: TrueSelfTemplateThinking[] = [];

  constructor(private dataService: TrueSelfTemplateDataService,
    private trueSelfDataService: TrueSelfBoardDataService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private userService: UserService) {

  }

  ngOnInit(): void {
    this.getThinking();
  }

  private getThinking() {
    this.trueSelfTemplates.forEach(e => {
      if (e.thinkingList?.length > 0) {
        const thinkingList = e.thinkingList?.map(x => ({
          ...x, trueSelfTemplateId: e.id,
          imageUrl: x.imageUrl ? x.imageUrl + blobKey : ""
        })) || [];
        this.dataSource.push(...thinkingList);
        this.filterDataSource = this.dataSource;
      }
    });

    this.dataService.thinking$.subscribe(res => {
      if (res && res.length > 0) {
        const currentTemplateId = res[0].trueSelfTemplateId;
        const templateIndex = this.trueSelfTemplates.findIndex(e => e.id === currentTemplateId);

        if (templateIndex !== -1) {
          this.currentSelectedTemplate = this.trueSelfTemplates[templateIndex];

          this.dataSource = this.dataSource.filter(x => x.trueSelfTemplateId !== currentTemplateId);
          const updatedThinking = res.map(x => ({
            ...x, trueSelfTemplateId: currentTemplateId,
            imageUrl: x.imageUrl ? x.imageUrl + blobKey : ""
          }));
          this.dataSource.push(...updatedThinking);
          this.filterDataSource = this.dataSource;

          this.currentSelectedTemplate.thinkingList = updatedThinking;
          this.trueSelfTemplates[templateIndex] = this.currentSelectedTemplate;
        }
      }
    });
  }

  handleUpdate(data) {
    const type = TrueSelfTemplateType.Thinking;
    const trueSelfTemplateId = data.trueSelfTemplateId;
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '500px';
    // dialogConfig.height = '300px';
    dialogConfig.data = {
      ...data, trueSelfTemplateId, type
    }
    const dialogRef = this.dialog.open(UpdateTrueSelfTemplateListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  handleChange(data) {
    const type = TrueSelfTemplateType.Thinking;
    const trueSelfTemplateId = data.trueSelfTemplateId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '400px';
    dialogConfig.data = {
      id: trueSelfTemplateId,
      typeId: data.id,
      type: "Thinking"
    };
    const dialogRef = this.dialog.open(TrueSelfTemplateTypeImageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      this.dataService.getThinkings(trueSelfTemplateId).subscribe();
    });
  }

  handleDelete(data) {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.dataService.deleteThinking(data.trueSelfTemplateId, data.id).subscribe();
      }
    });
  }

  handleLinkCard(data) {
    const id = data.id;
    const type = TrueSelfTemplateType.Thinking;
    const trueSelfTemplateId = data.trueSelfTemplateId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = '500px';
    dialogConfig.data = {
      trueSelfTemplateId, type, id
    };
    const dialogRef = this.dialog.open(TtLinkCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  handleShowCard(data) {
    const id = data.id;
    const type = TrueSelfTemplateType.Thinking;
    const trueSelfTemplateId = data.trueSelfTemplateId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = '500px';
    dialogConfig.data = {
      trueSelfTemplateId, type, id
    };
    const dialogRef = this.dialog.open(TtShowCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  copyTemplate(item) {
    this.trueSelfDataService.getTrueSelfByUser(this.userService.user.id).pipe(
      switchMap(res => {
        const thinking: TrueSelfThinking = {
          trueSelfId: res[0]?.id,
          name: item.name,
          imageUrl: item.imageUrl.split('?')[0],
        };

        return this.trueSelfDataService.createThinking(thinking);
      })
    ).subscribe(res => {
      this.toastr.success(`True Self Template card has been created using this template ${item.name}.`);
      this.updateVisionTemplateCopies(item);
    });
  }

  updateVisionTemplateCopies(template){
    const copy ={
      trueSelfTemplateId: template.trueSelfId,
      trueSelfTemplateType: "Thinking",
      user: this.userService.user.id,
      typeId:template.id,
    }
    this.dataService.updateTrueSelfTemplateCopies(copy).subscribe(res=>{
      template.copied.push(1);
    });
  }

  filter(value) {
    this.filterDataSource = value ? this.filterDataSource.filter(e => e.name.toLowerCase().includes(value.toLowerCase())) : this.dataSource;
  }
}
