import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OwxApiService } from "app/shared/services/generics/owx-api-service";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
import { VisionTemplate, VisionTemplateLeader, VisionTemplateLists, VisionTemplateQoute } from "../models/vision-template";

@Injectable({
    providedIn: 'root',
})
export class VisionTemplateDataService extends OwxApiService {
    private _leaders: BehaviorSubject<VisionTemplateLeader[]> = new BehaviorSubject<VisionTemplateLeader[]>(null);
    private _quotes: BehaviorSubject<VisionTemplateQoute[]> = new BehaviorSubject<VisionTemplateQoute[]>(null);
    private _lists: BehaviorSubject<VisionTemplateLists[]> = new BehaviorSubject<VisionTemplateLists[]>(null);

    get leaders$(): Observable<VisionTemplateLeader[]> {
        return this._leaders.asObservable();
    }

    get quotes$(): Observable<VisionTemplateQoute[]> {
        return this._quotes.asObservable();
    }

    get lists$(): Observable<VisionTemplateLists[]> {
        return this._lists.asObservable();
    }

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getVisionTemplates(): Observable<any> {
        return this.get("VisionTemplate");
    }

    getVisionTemplateByUser(userId: string): Observable<any> {
        return this.get("VisionTemplate/ByUser/" + userId);
    }

    getVisionTemplateLeaders(visionTemplateId: string): Observable<VisionTemplateLeader[]> {
        return this.get<VisionTemplateLeader[]>("VisionTemplate/get-leaders?visionTemplateId=" + visionTemplateId).pipe(
            tap((res: VisionTemplateLeader[]) => {
                res.forEach(e => {
                    e.visionTemplateId = visionTemplateId;
                });
                this._leaders.next(res);
                return res;
            })
        );
    }

    getVisionTemplateLists(visionTemplateId: string): Observable<VisionTemplateLists[]> {
        return this.get<VisionTemplateLists[]>("VisionTemplate/get-visionlists?visionTemplateId=" + visionTemplateId).pipe(
            tap((res: VisionTemplateLists[]) => {
                res.forEach(e => {
                    e.visionTemplateId = visionTemplateId;
                });
                this._lists.next(res);
                return res;
            })
        );
    }

    getVisionTemplateQoutes(visionTemplateId: string): Observable<VisionTemplateQoute[]> {
        return this.get<VisionTemplateQoute[]>("VisionTemplate/get-quotes?visionTemplateId=" + visionTemplateId).pipe(
            tap((res: VisionTemplateQoute[]) => {
                res.forEach(e => {
                    e.visionTemplateId = visionTemplateId;
                });
                this._quotes.next(res);
                return res;
            })
        );
    }

    createVisionTemplateHeader(visionTemplate: VisionTemplate) {
        return this.post("VisionTemplate/Header", visionTemplate);
    }

    createVisionTemplateLeader(visionTemplateLeader: VisionTemplateLeader) {
        return this.post("VisionTemplate/create-leader", visionTemplateLeader).pipe(
            switchMap(res => {
                return this.getVisionTemplateLeaders(visionTemplateLeader.visionTemplateId);
            })
        );
    }

    createVisionTemplateQoute(visionTemplateQoute: VisionTemplateQoute) {
        return this.post("VisionTemplate/create-quote", visionTemplateQoute).pipe(
            switchMap(res => {
                return this.getVisionTemplateQoutes(visionTemplateQoute.visionTemplateId);
            })
        );
    }

    createVisionTemplateLists(visionTemplateLists: VisionTemplateLists) {
        return this.post("VisionTemplate/create-visionlist", visionTemplateLists).pipe(
            switchMap(res => {
                return this.getVisionTemplateLists(visionTemplateLists.visionTemplateId);
            })
        );
    }

    deleteVisionTemplateLeader(visionTemplateId: string, leaderId: string) {
        return this.delete("VisionTemplate/delete-leader?visionTemplateId=" + visionTemplateId + "&leaderId=" + leaderId).pipe(
            switchMap(res => {
                return this.getVisionTemplateLeaders(visionTemplateId);
            })
        );
    }

    deleteVisionTemplateQoute(visionTemplateId: string, quoteId: string) {
        return this.delete("VisionTemplate/delete-quote?visionTemplateId=" + visionTemplateId + "&quoteId=" + quoteId).pipe(
            switchMap(res => {
                return this.getVisionTemplateQoutes(visionTemplateId);
            })
        );
    }

    deleteVisionTemplateLists(visionTemplateId: string, visionListsId: string) {
        return this.delete("VisionTemplate/delete-visionlist?visionTemplateId=" + visionTemplateId + "&visionListId=" + visionListsId).pipe(
            switchMap(res => {
                return this.getVisionTemplateLists(visionTemplateId);
            })
        );
    }

    updateVisionTemplateLeader(visionTemplateLeader: VisionTemplateLeader): Observable<any> {
        return this.put("VisionTemplate/update-leader", visionTemplateLeader).pipe(
            switchMap(res => {
                return this.getVisionTemplateLeaders(visionTemplateLeader.visionTemplateId);
            })
        );
    }

    updateVisionTemplateQuote(visionTemplateQuote: VisionTemplateQoute): Observable<any> {
        return this.put("VisionTemplate/update-quote", visionTemplateQuote).pipe(
            switchMap(res => {
                return this.getVisionTemplateQoutes(visionTemplateQuote.visionTemplateId);
            })
        );
    }

    updateVisionTemplateLists(visionTemplateLists: VisionTemplateLists): Observable<any> {
        return this.put("VisionTemplate/update-visionlist", visionTemplateLists).pipe(
            switchMap(res => {
                return this.getVisionTemplateLists(visionTemplateLists.visionTemplateId);
            })
        );
    }

    updateVisionTemplateCopies(data) {
        return this.post("VisionTemplate/update-copies",data) ;
    }
}