import { BidiModule } from '@angular/cdk/bidi';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { TtGlobalPrincipleComponent } from './components/tt-global-principle/tt-global-principle.component';
import { TtHealthyHabitComponent } from './components/tt-healthy-habit/tt-healthy-habit.component';
import { TtPersonalityDevelopComponent } from './components/tt-personality-develop/tt-personality-develop.component';
import { TtSkillComponent } from './components/tt-skill/tt-skill.component';
import { TtThinkingComponent } from './components/tt-thinking/tt-thinking.component';
import { VisionTemplateLeaderComponent } from './components/vision-template-leader/vision-template-leader.component';
import { VisionTemplateListsComponent } from './components/vision-template-lists/vision-template-lists.component';
import { VisionTemplateQouteComponent } from './components/vision-template-qoute/vision-template-qoute.component';
import { PipesModule } from './pipes/pipes.module';
import { FileUploadProgressService } from './services/file-upload-progress.service';
import { AchieveDetailDialogComponent } from './components/achieve-detail-dialog/achieve-detail-dialog.component';
import { StarReviewComponent } from './components/star-review/star-review.component';
import { DotsLoadingSpinnerComponent } from './components/dots-loading-spinner/dots-loading-spinner.component';
import { TruncateworldPipe } from './pipes/truncateworld.pipe';


export const MODULES = [
    CommonModule,
    FormsModule,
    NativeDateModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatDialogModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    MatTableModule,
    NgApexchartsModule,
    TranslateModule,
    ToastrModule.forRoot(),
    BidiModule,
    RouterModule
]

const sharedComponents=[
    TemplateCardComponent,
    VisionTemplateLeaderComponent,
    VisionTemplateListsComponent,
    VisionTemplateQouteComponent,
    ConfirmDialogComponent,
    TtGlobalPrincipleComponent,
    TtHealthyHabitComponent,
    TtPersonalityDevelopComponent,
    TtThinkingComponent,
    TtSkillComponent,
    FileUploadComponent,
    AchieveDetailDialogComponent,
    StarReviewComponent,
    DotsLoadingSpinnerComponent
]

@NgModule({
    imports: [
        MODULES,
        TruncateworldPipe
    ],
    exports: [
        MODULES,
        sharedComponents,
        TruncateworldPipe
    ],
    declarations: [
        sharedComponents
    ],
    providers:[
        FileUploadProgressService
    ]
})
export class SharedModule {

}