export interface VisionTemplate {
    id?:string;
    user:string;
    personality?: VisionTemplatePersonality[],
    visionList?: VisionTemplateLists[],
    leaders?: VisionTemplateLeader[],
    quotes?: VisionTemplateQoute[],
    copied?:any[];
}

export interface VisionTemplatePersonality{
    id?:string;
    visionTemplateId?: string,
    name: string,
    imageUrl: string,
    type?: string,
    user?: string
}

export interface VisionTemplateLeader{
    id?:string;
    visionTemplateId?: string,
    name: string,
    imageUrl: string,
    type?: string,
    user?: string;
    copied?:any[];
}

export interface VisionTemplateQoute{
    id?:string;
    visionTemplateId: string,
    name: string,
    imageUrl?: string,
    type?: string,
    user?: string;
    copied?:any[];
}

export interface VisionTemplateLists{
    id?:string;
    visionTemplateId: string,
    name: string,
    imageUrl?: string,
    type?: string,
    user?: string
    copied?:any[];
}

export enum VisionTemplateEnum{
    Leader=1,
    Qoute=2,
    List=3
}