import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { blobKey } from 'app/contants';
import { Subject } from 'rxjs';
import { UserService } from '../models/user/user.service';
import { User } from '../models/user/user.types';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    loginDisplay: boolean = false;
    @Input() showAvatar: boolean = true;
    user: User;
    @Output("close") close = new EventEmitter()
    private readonly _destroying$ = new Subject<void>();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
   @Output() isHamburgerMenuOpen = new EventEmitter<any>();
    constructor(private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private msalService: MsalService,
        private userSerivce: UserService) {
    }

    ngOnInit() {
        this.userSerivce.getUser(this.userSerivce.user.id).subscribe(res => {
            this.user = res;
            if (this.user.profileImageURL) {
                this.user.profileImageURL = this.user.profileImageURL + blobKey;
            }
        });
    }

    goToSettings() {
        this._router.navigate(['settings']);
    }
    toggleHamburgerMenu() {
        this.isHamburgerMenuOpen.emit(false);
       
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    logout() {
        this.userSerivce.logout();
        this.closeMenu();
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
    }

    editProfile() {
        this._router.navigate(['./user-profile']);
    }

    closeMenu() {
        this.close.emit();
    }
}