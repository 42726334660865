<button [matMenuTriggerFor]="userActions" class="px-1 mb-lg-3 border-none bg-white">
    <span class="relative">

        <img class="avatar-icon rounded-full"
         *ngIf="user?.profileImageURL" 
         [src]="user.profileImageURL">

        <mat-icon class="avatar-icon" 
        *ngIf="!user?.profileImageURL"
            [svgIcon]="'heroicons_outline:user-circle'">
        </mat-icon>

        <span class="absolute ltr:right-0 rtl:left-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'ltr:mr-px rtl:ml-px mb-px': !showAvatar || !user?.avatar,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}">
        </span>

    </span>
    <div class="profile_drop_down flex items-center ">
        <span class="font-bold">Me</span>
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</button>
<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item (click)="editProfile()" (click)="toggleHamburgerMenu()" >
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <!-- <button mat-menu-item (click)="closeMenu()" routerLink="/account/account">
        <mat-icon >supervisor_account</mat-icon>
        <span>Account</span>
    </button>
    <button mat-menu-item (click)="closeMenu()" routerLink="/account/settings">
        <mat-icon >settings</mat-icon>
        <span>Settings</span>
    </button> -->
    <button mat-menu-item (click)="closeMenu()" routerLink="/help-support">
        <mat-icon>help</mat-icon>
        <span>Help and Support</span>
    </button>
    <button mat-menu-item (click)="closeMenu()" routerLink="/account/survey">
        <mat-icon>assessment</mat-icon>
        <span>Survey</span>
    </button>
    <button mat-menu-item (click)="closeMenu()" routerLink="/account/refer">
        <mat-icon>share</mat-icon>
        <span>Refer a Friend</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <h2>Manage</h2>

    <button mat-menu-item (click)="closeMenu()" routerLink="/connect/expert" [queryParams]="{'tab':2}">
        <mat-icon>explicit</mat-icon>
        <span>Expert Page</span>
    </button>
    <button mat-menu-item (click)="closeMenu()" routerLink="/connect/business" [queryParams]="{'tab':2}">
        <mat-icon>business</mat-icon>
        <span>Business Page</span>
    </button>

    <button mat-menu-item (click)="closeMenu()" routerLink="/connect/group" [queryParams]="{'tab':2}">
        <mat-icon>group</mat-icon>
        <span>Group Page</span>
    </button>

    <mat-divider class="my-2"></mat-divider>

    <button mat-menu-item (click)="logout()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Logout</span>
    </button>

</mat-menu>