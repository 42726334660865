import { Injectable } from "@angular/core";
import { ChatAiApiService } from "app/shared/services/generics/chat-ai-api.service";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { ChatAI, ChatAIMessage } from "../models/chat-ai";
import { blobKey } from "app/contants";

@Injectable({
    providedIn: 'root',
})
export class ChatAIDataService extends ChatAiApiService {
    private viewSessionChat: BehaviorSubject<ChatAI> = new BehaviorSubject<ChatAI>({});

    getViewSessionChat() {
        return this.viewSessionChat.asObservable();
    }

    setViewSessionChat(chat: ChatAI) {
        this.getChatMessages(chat.id).subscribe(res => {
            chat.messages = res;
            chat.messages.forEach(e => e.imageURL = e.imageURL + blobKey);
            this.viewSessionChat.next(chat);
        });
    }

    getChatById(id: string): Observable<any> {
        return this.get("ChatAI/" + id);
    }

    getChatsByUser(userId: string): Observable<any> {
        return this.get("ChatAI/ByUser/" + userId);
    }

    createChat(chatAi: ChatAI): Observable<any> {
        return this.post("ChatAI/", chatAi);
    }

    updateChat(chatAi: ChatAI): Observable<any> {
        return this.put("ChatAI/", chatAi);
    }

    deleteChat(id): Observable<any> {
        return this.delete("ChatAI/" + id);
    }

    getChatMessages(chatId: string): Observable<any> {
        return this.get(`ChatAI/messages?ChatAIId=${chatId}`);
    }

    createMessage(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/create-message", message);
    }

    creatXCardMessage(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/create-xcard", message);
    }

    listAllXCard(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/list-all-xcard", message);
    }

    getOneWorldX(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/oneWorldX", message);
    }

    createChatMessage(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/create-chatai-message", message);
    }

    updateChatMessage(message: ChatAIMessage): Observable<any> {
        return this.post("ChatAI/update-chatai-message", message);
    }

    deleteChatMessage(chatAiId: string, messageId: string): Observable<any> {
        return this.delete(`ChatAI/delete-chatai-message?ChatAIId=${chatAiId}&messageId=${messageId}`);
    }
}