import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { AiAssistantComponent } from './ai-assistant.component';
import { AiChatComponent } from './ai-chat/ai-chat.component';
import { ChatAssistantHistoryDialogComponent } from './chat-assistant-history-dialog/chat-assistant-history-dialog.component';

@NgModule({
  declarations: [
    AiAssistantComponent,
    AiChatComponent,
    ChatAssistantHistoryDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[AiAssistantComponent,AiChatComponent]
  
})
export class AiAssistantModule { }
