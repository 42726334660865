import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VisionBoardVision } from 'app/modules/my-life/vision/models/vision-board';
import { VisionBoardDataService } from 'app/modules/my-life/vision/services/vision-board-data.service';
import { UpdateListDialogComponent } from 'app/modules/my-life/vision/vision-template/components/update-list-dialog/update-list-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { VisionTemplate, VisionTemplateEnum, VisionTemplateLists } from '../../../modules/my-life/vision/vision-template/models/vision-template';
import { VisionTemplateDataService } from '../../../modules/my-life/vision/vision-template/services/vision-template-data.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserService } from 'app/layout/models/user/user.service';
import { switchMap } from 'rxjs';
import { blobKey } from 'app/contants';
import { VisionTemplateTypeImageDialogComponent } from 'app/modules/my-life/vision/vision-template/components/vision-template-type-image-dialog/vision-template-type-image-dialog.component';

@Component({
  selector: 'owx-vision-template-lists',
  templateUrl: './vision-template-lists.component.html',
  styleUrl: './vision-template-lists.component.scss'
})
export class VisionTemplateListsComponent {
  @Input() visionTemplates: VisionTemplate[];
  @Input() showCopyTemplate: boolean = false;
  @Input() heading:any;
  visionTemplateLists: VisionTemplateLists[] = [];
  displayedColumns: string[] = ['picture', 'name', 'action'];
  dataSource: VisionTemplateLists[] = [];
  currentSelectedTemplate: VisionTemplate;
  filterDataSource: VisionTemplateLists[] = [];

  constructor(private dataService: VisionTemplateDataService,
    private visionDataService: VisionBoardDataService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private userService: UserService) {

  }

  ngOnInit(): void {
    this.getLists();
  }

  private getLists() {
    this.visionTemplates.forEach(e => {
      const visionList = e.visionList?.map(x => ({
        ...x, visionTemplateId: e.id,
        imageUrl: x.imageUrl ? x.imageUrl + blobKey : ""
      })) || [];
      this.dataSource.push(...visionList);
      this.filterDataSource = this.dataSource;
    });

    this.dataService.lists$.subscribe(res => {
      if (res && res.length > 0) {
        const currentTemplateId = res[0].visionTemplateId;
        const templateIndex = this.visionTemplates.findIndex(e => e.id === currentTemplateId);

        if (templateIndex !== -1) {
          this.currentSelectedTemplate = this.visionTemplates[templateIndex];

          this.dataSource = this.dataSource.filter(x => x.visionTemplateId !== currentTemplateId);
          const updatedLists = res.map(x => ({
            ...x, visionTemplateId: currentTemplateId,
            imageUrl: x.imageUrl ? x.imageUrl + blobKey : ""
          }));
          this.dataSource.push(...updatedLists);
          this.filterDataSource = this.dataSource;

          this.currentSelectedTemplate.visionList = updatedLists;
          this.visionTemplates[templateIndex] = this.currentSelectedTemplate;
        }
      }
    });
  }

  handleChange(data) {
    const type = VisionTemplateEnum.List;
    const visionTemplateId = data.visionTemplateId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = '400px';
    dialogConfig.data = {
      id: visionTemplateId,
      typeId: data.id,
      type: "VisionList"
    };
    const dialogRef = this.dialog.open(VisionTemplateTypeImageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      this.dataService.getVisionTemplateLists(visionTemplateId).subscribe();
    });
  }

  handleUpdate(data) {
    const type = VisionTemplateEnum.List;
    const visionTemplateId = data.visionTemplateId;
    var dialogConfig = new MatDialogConfig();
    // // dialogConfig.width = '500px';
    // // dialogConfig.height = '300px';
    dialogConfig.data = {
      ...data, type, visionTemplateId
    };
    const dialogRef = this.dialog.open(UpdateListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  handleDelete(data) {
    const dialogConfig = new MatDialogConfig();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.dataService.deleteVisionTemplateLists(data.visionTemplateId, data.id).subscribe();
      }
    });
  }

  copyTemplate(item) {

    this.visionDataService.getVisionBoardByUser(this.userService.user.id).pipe(
      switchMap(res => {
        const lists: VisionBoardVision = {
          visionUserId: res[0]?.id,
          name: item.name,
          imageUrl: item.imageUrl.split('?')[0],
        };

        return this.visionDataService.createVisionBoardVision(lists);
      })
    ).subscribe(res => {
      this.toastr.success(`Vision card has been created using this template ${item.name}.`);
      this.updateVisionTemplateCopies(item);
    });
  }

  updateVisionTemplateCopies(template){
    const copy ={
      visionTemplateId: template.visionTemplateId,
      visionTemplateType: "VisionList",
      user: this.userService.user.id,
      typeId:template.id,
    }
    this.dataService.updateVisionTemplateCopies(copy).subscribe(res=>{
      template.copied.push(1);
    });
  }

  filter(value) {
    this.filterDataSource = value ? this.filterDataSource.filter(e => e.name.toLowerCase().includes(value.toLowerCase())) : this.dataSource;
  }
}
