import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatAiMessageType, blobKey } from 'app/contants';
import { UserService } from 'app/layout/models/user/user.service';
import { isEmpty } from 'lodash';
import { ChatAI, ChatAIMessage } from '../models/chat-ai';
import { ChatAIDataService } from '../services/chat-ai-data.service';
import { Router } from '@angular/router';

const CREATE_XCARD_PROMPT_1 = 'What do you want to achieve?';
const CREATE_XCARD_PROMPT_2 = 'Create Xcard with name ';

@Component({
  selector: 'ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrl: './ai-chat.component.scss'
})
export class AiChatComponent implements OnInit {
  showChat: boolean = false;
  currentChat: ChatAI;
  messages: [] = [];
  chatAiMessageType = ChatAiMessageType;
  loading: boolean = false;
  senderName = this.userService.user.displayName;
  createXCardFlow = false;
  oneWorldXFlow = false;
  listXCardsFlow = false;
  @ViewChild('messageContainer') private messageContainer: ElementRef;

  constructor(private dataService: ChatAIDataService,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.dataService.getViewSessionChat().subscribe(res => {
      this.currentChat = res;
      if (!isEmpty(this.currentChat)) {
        this.showChat = true;
      }
    });

    this.createChatSession();
  }

  createChatSession() {
    const chatAI: ChatAI = {
      name: this.userService.user.displayName,
      user: this.userService.user.id,
      type: ChatAiMessageType.Human,
      tokensUsed: 0
    };

    this.dataService.createChat(chatAI).subscribe(res => {
      this.currentChat = res;
      this.currentChat.messages = [];
    });
  }

  sendMessage(value, type?) {
    if (!value) {
      return;
    }

    this.cdr.detectChanges();
    this.scrollToBottom();
    
    this.showChat = true;

    const message: ChatAIMessage = {
      chatAIId: this.currentChat.id,
      tokens: value.split(' ').length,
      sender: this.userService.user.id,
      type: ChatAiMessageType.Human,
      imageURL: this.userService.user.profileImageURL,
      userId: this.userService.user.id,
      xCardName: value,
      text: value
    };

    if (this.createXCardFlow) {
      this.loading = true;
      message.text = CREATE_XCARD_PROMPT_2 + value;
      this.dataService.creatXCardMessage(message).subscribe(res => {
        res.aiResponse.imageURL = res.aiResponse.imageURL + blobKey;
        res.aiResponse.type = ChatAiMessageType.System;
        this.currentChat?.messages.push(res.aiResponse);
        this.loading = false;

        // if (this.createXCardFlow && res.aiResponse.text == "Successfully Xcard created") {
        if (this.createXCardFlow && res.aiResponse.text.includes("successfully")) {
          this.router.navigate(['/my-life/achieve/board']).then(() => {
            // window.location.reload();
            this.createXCardFlow = false;
            this.cdr.detectChanges();
            this.scrollToBottom();
          });;
        }
      });
    }

    if (this.listXCardsFlow) {
      this.dataService.creatXCardMessage(message).subscribe(res => {
        res.aiResponse.imageURL = res.aiResponse.imageURL + blobKey;
        res.aiResponse.type = ChatAiMessageType.System;

        const cards = JSON.parse(res.aiResponse.text);
        res.aiResponse.text = "";

        cards.forEach((element, index) => {
          res.aiResponse.text += `Card - ${index + 1}: ${element.title}\n`;
        });

        this.currentChat?.messages.push(res.aiResponse);
        this.loading = false;
        this.listXCardsFlow = false;
        this.cdr.detectChanges();
        this.scrollToBottom();
      });
    }

    if (this.oneWorldXFlow) {
      this.loading = true;
      this.dataService.getOneWorldX(message).subscribe(res => {
        res.aiResponse.imageURL = res.aiResponse.imageURL + blobKey;
        res.aiResponse.type = ChatAiMessageType.System;

        const oneWorldX = JSON.parse(res.aiResponse.text);
        res.aiResponse.text = oneWorldX.answer;

        this.currentChat?.messages.push(res.aiResponse);
        this.loading = false;
        this.oneWorldXFlow = false;

        this.cdr.detectChanges();
        this.scrollToBottom();
      });
    }

    if (!this.listXCardsFlow && !this.createXCardFlow && !this.oneWorldXFlow) {
      this.loading = true;
      this.dataService.createChatMessage(message).subscribe(res => {
        if (!isEmpty(res)) {
          res.userMessage.imageURL = res.userMessage.imageURL + blobKey;
          res.aiResponse.imageURL = res.aiResponse.imageURL + blobKey;
          res.aiResponse.type = ChatAiMessageType.System;
          this.currentChat?.messages.push(res.userMessage);
          this.currentChat?.messages.push(res.aiResponse);
          this.loading = false;
        }
        this.loading = false;

        this.cdr.detectChanges();
        this.scrollToBottom();
      });
    }
  }

  initiateCreateXCardPrompt(prompt) {
    this.createXCardFlow = true;
    this.showChat = true;
    this.loading = true;
    const messagePrompt: ChatAIMessage = {
      chatAIId: this.currentChat.id,
      text: prompt,
      tokens: prompt.split(' ').length,
      sender: this.userService.user.id,
      type: ChatAiMessageType.Human,
      imageURL: this.userService.user.profileImageURL
    };

    this.cdr.detectChanges();
    this.scrollToBottom();
    this.dataService.createMessage(messagePrompt).subscribe(res => {
      this.loading = false;
      res.imageURL = res.imageURL + blobKey;
      this.currentChat?.messages.push(res);
      this.createXCreateSystemResponse(prompt);
      this.scrollToBottom();
    });
  }

  private createXCreateSystemResponse(prompt: any) {
    this.loading = true;
    const messageSystemPrompt: ChatAIMessage = {
      chatAIId: this.currentChat.id,
      text: CREATE_XCARD_PROMPT_1,
      tokens: prompt.split(' ').length,
      sender: this.userService.user.id,
      type: ChatAiMessageType.System
    };

    this.dataService.createMessage(messageSystemPrompt).subscribe(res => {
      this.loading = false;
      res.imageURL = res.imageURL + blobKey;
      this.currentChat?.messages.push(res);
      this.scrollToBottom();
    });
  }

  oneWorldXPrompt(prompt) {
    this.oneWorldXFlow = true;
    this.showChat = true;
    this.loading = true;
    const messagePrompt: ChatAIMessage = {
      chatAIId: this.currentChat.id,
      text: prompt,
      tokens: prompt.split(' ').length,
      sender: this.userService.user.id,
      type: ChatAiMessageType.Human,
      imageURL: this.userService.user.profileImageURL
    };

    this.dataService.createMessage(messagePrompt).subscribe(res => {
      res.imageURL = res.imageURL + blobKey;
      this.currentChat?.messages.push(res);
    this.scrollToBottom();
    this.sendMessage(prompt);
    });
  }

  listAllXCardsPrompt(prompt) {
    this.listXCardsFlow = true;
    this.showChat = true;
    this.loading = true;
    const messagePrompt: ChatAIMessage = {
      chatAIId: this.currentChat.id,
      text: prompt,
      tokens: prompt.split(' ').length,
      sender: this.userService.user.id,
      type: ChatAiMessageType.Human,
      imageURL: this.userService.user.profileImageURL
    };

    this.dataService.createMessage(messagePrompt).subscribe(res => {
      res.imageURL = res.imageURL + blobKey;
      this.currentChat?.messages.push(res);
      this.scrollToBottom();
      this.sendMessage(prompt);
    });
  }

  scrollToBottom(): void {
    try {
      if (this.messageContainer) {
        this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.log('Scroll error:', err);
    }
  }
}
