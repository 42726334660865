import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'owx-tt-link-card',
  templateUrl: './tt-link-card.component.html',
  styleUrls: ['./tt-link-card.component.scss']
})
export class TtLinkCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
