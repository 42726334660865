import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Template } from 'app/modules/my-life/models/template';

@Component({
  selector: 'owx-template-card',
  templateUrl: './template-card.component.html',
  styleUrl: './template-card.component.scss'
})
export class TemplateCardComponent implements OnInit {
  @Input() template:Template;
  constructor(private router: Router){

  }

  ngOnInit(): void {

  }

  redirect(id){
    this.router.navigate(['/my-life/list/details']);
  }
}
