import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'owx-tt-show-card',
  templateUrl: './tt-show-card.component.html',
  styleUrls: ['./tt-show-card.component.scss']
})
export class TtShowCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
