import { Component } from '@angular/core';

@Component({
  selector: 'ai-assistant',
  templateUrl: './ai-assistant.component.html',
  styleUrl: './ai-assistant.component.scss'
})
export class AiAssistantComponent {
  
}
