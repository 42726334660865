import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'environments/environment';

export const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
export const GRAPH_ENDPOINT_PIC = 'https://graph.microsoft.com/v1.0/me/photo/$value';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 
export const b2cPolicies = {
    names: {
         signUpSignIn: "B2C_1_SignInSignUp",
         editProfile: "B2C_1_ProfileEdit",
         passwordReset: "B2C_1_PasswordReset",
         signUp: "B2C_1_SignUp",
         signIn:"B2C_1_SignIn"
    },
    authorities: {
         signUpSignIn: {
             authority: "https://oneworldxb2c.b2clogin.com/oneworldxb2c.onmicrosoft.com/B2C_1_SignInSignUp",
          },
          signUp: {
            authority: "https://oneworldxb2c.b2clogin.com/oneworldxb2c.onmicrosoft.com/B2C_1_SignUp",
         },
         signIn: {
            authority: "https://oneworldxb2c.b2clogin.com/oneworldxb2c.onmicrosoft.com/B2C_1_SignIn",
         },
         editProfile: {
             authority: "https://oneworldxb2c.b2clogin.com/oneworldxb2c.onmicrosoft.com/B2C_1_ProfileEdit"
         },
         passwordReset: {
            authority: "https://oneworldxb2c.b2clogin.com/oneworldxb2c.onmicrosoft.com/B2C_1_PasswordReset"
        }
     },
     authorityDomain: "oneworldxb2c.b2clogin.com"
 };
 
export const msalConfig: Configuration = {
     auth: {
         clientId: '997c59c9-573d-4b03-888e-c17fff380861',
         authority: b2cPolicies.authorities.signIn.authority,
         knownAuthorities: [b2cPolicies.authorityDomain],
         redirectUri: '/'
     },
     cache: {
         cacheLocation: BrowserCacheLocation.LocalStorage,
         storeAuthStateInCookie: isIE, 
     },
     system: {
         loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
             },
             logLevel: LogLevel.Verbose,
             piiLoggingEnabled: false
         }
     }
 }

export const protectedResources = {
  owxApi: {
    endpoint: environment.owxSectionApiUrl + "message",
    scopes: ["https://oneworldxb2c.onmicrosoft.com/997c59c9-573d-4b03-888e-c17fff380861/weather.read"],
  },
}

export const loginRequest = {
  scopes: []
};