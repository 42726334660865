export class User {
    id: string;
    email?: string;
    roles?: Role[];
    avatar?: string;
    status?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    displayName?: string;
    mobile?: string;
    username?: string;
    password?: string;
    intro?: string;
    profile?: string;
    profileImageURL?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    identityVerified?: true;
    encourage?: UserEncourage[];
    selected?: boolean;
    view?: any[];
    follow?:any[];
}

export enum UserRole {
    Basic = 'basic',
    Admin = 'admin'
}

export interface Role {
    userId: string;
    type: string;
    user: string;
}

export class UserEncourage {
    id?: string;
    count: number;
    user: string;
    userId:string;
    userName?:string;
    userProfileImage?:string;
}
