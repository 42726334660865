<div class="flex flex-col items-center justify-center p-4 border-2 rounded-md border-gray-300">
    <div *ngIf="!previewImageUrl" class="flex flex-col items-center space-y-4">
        <p class="text-lg font-semibold text-gray-700">Drag and drop files here</p>
        <span class="text-gray-400">or</span>
        <label for="file-upload" class="cursor-pointer hover:text-gray-600">
            <span
                class="inline-block rounded-md border border-gray-300 bg-gray-100 px-4 py-2 text-base font-medium text-gray-700 leading-6 transition duration-150 ease-in-out">
                Browse Files
            </span>
            <input type="file" id="file-upload" name="file-upload" class="sr-only"
                (change)="handleFileUpload($event)" />
        </label>
    </div>
    <div *ngIf="previewImageUrl" id="image-preview" class="mt-4 flex items-center space-x-4">
        <div class="flex-shrink-0 w-12 h-12 rounded-md overflow-hidden border-2 border-gray-300 uploaded-image">
            <img [src]="previewImageUrl" alt="Preview image" />
        </div>
        <div class="flex-grow">
            <p class="text-sm font-medium text-gray-700">{{ fileName }}</p>
            <p class="text-xs text-gray-500">{{ uploadProgress }}%</p>
        </div>
        <button type="button" class="text-gray-500 hover:text-gray-700" (click)="cancelUpload()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="none">
                <path
                    d="M6.2832 6.2832L10 10L13.7168 6.2832C14.6458 5.32031 16.2426 5.32031 17.1716 6.2832L19.9999 9.00004L19.9999 11.0001L17.1716 13.7168C16.2426 14.6458 14.6458 14.6458 13.7168 13.7168L10 10L6.2832 6.2832Z"
                    fill="#212529" />
            </svg>
        </button>
    </div>
    <mat-progress-bar *ngIf="uploadProgress !== undefined" mode="determinate" class="p-8"
        [value]="uploadProgress"></mat-progress-bar>
</div>