<div class="relative flex flex-col flex-none place-content-center min-h-8">
    <mat-slider class="absolute top-0 place-content-star m-0 p-0 box-border" style="z-index:3;opacity: 0;" [disabled]="readonly"
        [disabled]="isDisabled" [max]="5" [min]="0" [step]="step" [discrete]="false"
        [ngStyle]="{height:width+'px','min-width':width*5+'px'}" [showTickMarks]="false">
        <input matSliderThumb [(ngModel)]="rankbySlide" [disabled]="readonly">
    </mat-slider>

    <div class="flex flex-none absolute top-0 place-content-start" style="height: inherit;z-index: 1;">
        <div class="star"
            [ngStyle]="{'--width':width+'px','--fullWidth':(width*5)+'px',height:width+'px', width:width+'px'}"
            *ngFor="let a of [1,2,3,4,5]">
            <div class="rtl:hidden"
                [ngStyle]="{height:width+'px','clip-path':(rankbySlide < a ) ? 'polygon(0 0, '+(rankbySlide % 1)*width+'px 0, '+(rankbySlide % 1)*width+ 'px '+width+'px,0 '+width+'px)': ''}"
                *ngIf="rankbySlide > (a-1)">
            </div>
            <div class="ltr:hidden"
                [ngStyle]="{height:width+'px','clip-path':(rankbySlide < a ) ? 'polygon(100% 0, '+(rankbySlide % 1)*width+'px 0, '+(rankbySlide % 1)*width+ 'px '+width+'px,100% '+width+'px)': ''}"
                *ngIf="rankbySlide > (a-1)">
            </div>
            <img src="/assets/images/emptyStar.svg" [width]="width" [height]="height">
        </div>
    </div>
</div>