import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';

@Component({
  selector: 'owx-star-review',
  templateUrl: './star-review.component.html',
  styleUrl: './star-review.component.scss'
})
export class StarReviewComponent implements OnInit {
  @Output('emitRating') emitRating = new EventEmitter<number>();
  _onChange(val: any) { }
  _onTouched() { }
  isDisabled = false;

  @Input() width = 20;
  @Input() step = 0.5;
  @Input() ratingValue =0;
  @Input() readonly = false;

  @Input() height = 20;

  ngOnInit(): void {
    if(this.ratingValue > 0){
      this.rankbySlide = this.ratingValue;
    }
  }

  writeValue(obj: any): void {
    if (obj >= 0 && obj <= 5) {
      this.rankbySlide = obj;
    } else this.rankbySlide = Math.round(obj % 5);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  _rank = 0;
  cdr = inject(ChangeDetectorRef);
  set rankbySlide(r) {
    this._rank = r;
    this._onChange(r);
    this.cdr.detectChanges();
    this.emitRating.emit(this._rank);
  }

  get rankbySlide() {
    return this._rank;
  }
}
