<div class="px-2">
    <mat-card class="general-card" (click)="redirect(template.id)">
      <mat-card-header class="p-0">
        <div class="img-card">
          <img src="{{template.imageUrl}}" class="w-full h-2/5 object-cover rounded-t-lg">
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="px-2 my-4">
          <h5 class="text-xl font-bold">{{template.name}}</h5>
          <span class="text-medium">{{template.description}}</span>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="card-f flex justify-between items-center p-4">
          <div class="flex items-center">
            <button type="button" class="icon-btn me-2">
              <mat-icon>thumb_up</mat-icon>
            </button>
            <span class="text-medium">Encourage</span>
          </div>
          <div class="flex items-center mx-3">
            <button type="button" class="icon-btn me-2">
              <mat-icon>content_copy</mat-icon>
            </button>
            <span class="text-medium">Copied</span>
          </div>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>