import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ICONS } from 'assets/icons/icons';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    loginDisplay:boolean = false;
    
    constructor(private title: Title,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer) {
        // this.title.setTitle('OWX');
        this.title.setTitle('OneWorldX - A Human Life Intelligent Platform');

        ICONS.forEach(icon => {
            this.matIconRegistry.addSvgIcon(
              icon,
              this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/icons/${icon}.svg`)
            );
        });
    }
}
