import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VisionTemplateEnum } from '../../models/vision-template';
import { VisionTemplateDataService } from '../../services/vision-template-data.service';
import { CreateListDialogComponent } from '../create-list-dialog/create-list-dialog.component';

@Component({
  selector: 'owx-update-list-dialog',
  templateUrl: './update-list-dialog.component.html',
  styleUrl: './update-list-dialog.component.scss'
})
export class UpdateListDialogComponent implements OnInit {
  updateListForm:FormGroup = new FormGroup({
    visionTemplateType: new FormControl('', Validators.required),
    visionTemplateId: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    imageUrl: new FormControl(''),
    type: new FormControl(''),
    user:new FormControl('')
  });

  constructor(@Inject(MAT_DIALOG_DATA) private data, 
    private dialogRef: MatDialogRef<CreateListDialogComponent>,
    private dataService: VisionTemplateDataService){

  }

  ngOnInit(): void {
    this.updateListForm.patchValue(this.data);
    this.updateListForm.controls.visionTemplateType.setValue(''+this.data.type);
    this.updateListForm.controls.visionTemplateType.disable();
  }

  onSubmit(){
    this.updateListForm.controls.visionTemplateId.setValue(this.data.visionTemplateId);
    this.updateListForm.controls.visionTemplateType.enable();

    if(!this.updateListForm.valid)
      return false;

    switch(+this.updateListForm.value.visionTemplateType){
      case VisionTemplateEnum.Leader:
        this.submitLeader()
        break;
      case VisionTemplateEnum.List:
        this.submitList()
        break;
      case VisionTemplateEnum.Qoute:
        this.submitQoute();
        break;
      default:
        break;
    }

    this.dialogRef.close(true);
  }

  submitLeader(){
    var leader = this.updateListForm.value;
    leader.leaderId = this.data.id;
    leader.visionTemplateId = this.data.visionTemplateId;
    leader.type = ''+this.data.type;
    leader.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateVisionTemplateLeader(leader).subscribe();
  }

  submitQoute(){
    var qoute = this.updateListForm.value;
    qoute.quoteId = this.data.id;
    qoute.visionTemplateId = this.data.visionTemplateId;
    qoute.type = ''+this.data.type;
    qoute.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateVisionTemplateQuote(qoute).subscribe();
  }

  submitList(){
    var list = this.updateListForm.value;
    list.visionListId = this.data.id;
    list.visionTemplateId = this.data.visionTemplateId;
    list.type = ''+this.data.type;
    list.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateVisionTemplateLists(list).subscribe();
  }
}
