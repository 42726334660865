<div class="d-flex justify-between align-items-center">
  <h2 mat-dialog-title class="mb-0 fw-600 text-muted-8">Update</h2>
  <mat-icon mat-dialog-close class="close-modal-icon text-primary cursor-pointer">close</mat-icon>
</div>

<mat-dialog-content >
  <form [formGroup]="updateListForm" (ngSubmit)="onSubmit()">
    <div class="containter p-lg-2">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="fill" style="width: 100%; background-color: transparent;" [floatLabel]="'always'"
            >
            <mat-label>Select</mat-label>
            <mat-select formControlName="visionTemplateType">
              <mat-option value="1">Leader</mat-option>
              <mat-option value="2">Quote</mat-option>
              <mat-option value="3">Life</mat-option>
            </mat-select>
            <mat-error *ngIf="updateListForm.get('visionTemplateType').hasError('required')">
              Please select an option
            </mat-error>
          </mat-form-field>

        </div>

        <div class="col-md-12">
          <mat-form-field appearance="fill" style="width: 100%; background-color: transparent;">
            <input matInput placeholder="Enter a brief detail" formControlName="name">
            <mat-error *ngIf="updateListForm.get('name').hasError('required')">
              Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12">
          <div class="d-flex justify-content-start">
            <button type="submit" mat-button color="primary" class="primary-mat-btn mat-icon-btn">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

</mat-dialog-content>