import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class FileUploadProgressService {
    private _progress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    get progress$() {
        return this._progress.asObservable();
    }

    setProgress(progress) {
        this._progress.next(progress);
    }
}