import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatAI } from '../models/chat-ai';
import { ChatAIDataService } from '../services/chat-ai-data.service';
import { UserService } from 'app/layout/models/user/user.service';

@Component({
  selector: 'owx-chat-assistant-history-dialog',
  templateUrl: './chat-assistant-history-dialog.component.html',
  styleUrl: './chat-assistant-history-dialog.component.scss'
})
export class ChatAssistantHistoryDialogComponent {
  chats:ChatAI[]=[];
  constructor(private dialogRef: MatDialogRef<ChatAssistantHistoryDialogComponent>,
    private dataService: ChatAIDataService,
    private userService: UserService) {
    this.dataService.getChatsByUser(this.userService.user.id).subscribe(res=>{
      this.chats = res;
    });
  }

  onCancel(){
    this.dialogRef.close();
  }

  view(chat){
    this.dataService.setViewSessionChat(chat);
    this.dialogRef.close();
  }
}
