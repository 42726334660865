import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadProgressService } from 'app/shared/services/file-upload-progress.service';

@Component({
  selector: 'owx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input() achieveId: string;
  @Output() uploadEmitter = new EventEmitter();
  previewImageUrl: string;
  fileName: string;
  uploadProgress: number;

  constructor(private fileUploadProgressService: FileUploadProgressService) {
    this.fileUploadProgressService.progress$.subscribe(res=>{
      this.uploadProgress = res;
    });
  }

  handleFileUpload(event: any): void {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      this.fileName = file.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewImageUrl = e.target?.result as string;
      };

      reader.readAsDataURL(file);
      this.uploadFile(file);
    }
  }

  uploadFile(file: File): void {
    this.uploadEmitter.emit(file);
    this.resetUpload();
  }

  resetUpload(): void {
    this.uploadProgress = undefined;
    this.previewImageUrl = null;
    this.fileName = undefined;
    const fileInput = document.getElementById('file-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  cancelUpload(): void {
    this.uploadProgress = undefined;
    this.previewImageUrl = 'placeholder.png';
    this.fileName = undefined;
    const fileInput = document.getElementById('file-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
}
