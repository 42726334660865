import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrueSelfBoardType } from 'app/modules/my-life/true-self/models/true-self-board';
import { TrueSelfTemplateDataService } from '../../../services/true-self-template-data.service';

@Component({
  selector: 'owx-update-true-self-template-list-dialog',
  templateUrl: './update-true-self-template-list-dialog.component.html',
  styleUrls: ['./update-true-self-template-list-dialog.component.scss']
})
export class UpdateTrueSelfTemplateListDialogComponent implements OnInit {
  updateListForm: FormGroup = new FormGroup({
    trueSelfTemplateType: new FormControl('', Validators.required),
    trueSelfTemplateId: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    imageUrl: new FormControl(''),
    type: new FormControl(''),
    user: new FormControl('')
  });

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<UpdateTrueSelfTemplateListDialogComponent>,
    private dataService: TrueSelfTemplateDataService) {

  }

  ngOnInit(): void {
    this.updateListForm.patchValue(this.data);
    this.updateListForm.controls.trueSelfTemplateType.setValue(''+this.data.type);
    this.updateListForm.controls.trueSelfTemplateType.disable();
  }

  onSubmit() {
    this.updateListForm.controls.trueSelfTemplateId.setValue(this.data.trueSelfTemplateId);
    this.updateListForm.controls.trueSelfTemplateType.enable();

    if (!this.updateListForm.valid)
      return false;

    switch (+this.updateListForm.value.trueSelfTemplateType) {
      case TrueSelfBoardType.GlobalPrinciple:
        this.submitGlobalPrinciple();
        break;
      case TrueSelfBoardType.HealthyHabit:
        this.submitHealthyHabit()
        break;
      case TrueSelfBoardType.PersonalityDevelop:
        this.submitPersonalityDevelop()
        break;
      case TrueSelfBoardType.Thinking:
        this.submitThinking()
        break;
      case TrueSelfBoardType.Skill:
        this.submitSkill()
        break;
      default:
        break;
    }

    this.dialogRef.close(this.updateListForm.value);
  }

  submitGlobalPrinciple() {
    const data = this.updateListForm.value;
    data.globalPrincipleId = this.data.id;
    data.type = ''+ this.data.type;
    data.trueSelfTemplateId = this.data.trueSelfTemplateId;
    data.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updatetrueSelfTemplateGlobalPrinciple(data).subscribe();
  }

  submitPersonalityDevelop() {
    const data = this.updateListForm.value;
    data.personalityDevelopId = this.data.id;
    data.type = ''+ this.data.type;
    data.trueSelfTemplateId = this.data.trueSelfTemplateId;
    data.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updatePersonalityDevelop(data).subscribe();
  }

  submitThinking() {
    const data = this.updateListForm.value;
    data.thinkingId = this.data.id;
    data.type = ''+ this.data.type;
    data.trueSelfTemplateId = this.data.trueSelfTemplateId;
    data.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateThinking(data).subscribe();
  }

  submitSkill() {
    const data = this.updateListForm.value;
    data.skillId = this.data.id;
    data.type = ''+ this.data.type;
    data.trueSelfTemplateId = this.data.trueSelfTemplateId;
    data.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateSkill(data).subscribe();
  }

  submitHealthyHabit() {
    const data = this.updateListForm.value;
    data.healthyHabitId = this.data.id;
    data.type = ''+ this.data.type;
    data.trueSelfTemplateId = this.data.trueSelfTemplateId;
    data.imageUrl = this.data.imageUrl.split('?')[0],
    this.dataService.updateHealthyHabit(data).subscribe();
  }

}
