<div class="container pt-4">
    <div class="flex flex-row justify-between">
        <div class="flex flex-row justify-between w-full">
            <div class="px-3">
                <h3 class="font-bold">Your Chat Sessions</h3>
            </div>
            <button mat-icon-button class="cancel-button" (click)="onCancel()">
                <mat-icon aria-label="Close dialog">close</mat-icon>
            </button>
        </div>
    </div>


    <ul class="list-none p-0">
        <ng-container *ngFor="let item of chats; let i = index;">
            <li class="flex items-center justify-between mb-2">
                <div class="flex flex-col items-center">
                    <div>
                        <span class="mr-2">Session #{{i+1}} {{item.name}} </span>
                        <span class="mr-2"> ({{item?.messages?.length}}) </span>
                    </div>
                    <p class="mr-2">{{item.created | date: "MMM dd yyyy hh:mm a"}}</p>
                </div>
                
                <div class="flex items-center">
                    <button mat-button (click)="view(item)" color="primary">
                        View
                    </button>
                </div>
            </li>
        </ng-container>
    </ul>

</div>