export interface TrueSelfTemplate {
    id?:string;
    user: string;
    globalPrinciplesList?:TrueSelfTemplateGlobalPrinciple[],
    personalityDevelopList?:TrueSelfTemplatePersonalityDevelop[]
    healthyHabitsList?: TrueSelfTemplateHealthyHabit[],
    skillsList?: TrueSelfTemplateSkill[],
    thinkingList?:TrueSelfTemplateThinking[]
}

export interface TrueSelfTemplateGlobalPrinciple {
    id?:string;
    trueSelfTemplateId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    copied?:any[];
}

export interface TrueSelfTemplatePersonalityDevelop {
    id?:string;
    trueSelfTemplateId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    copied?:any[];
    user?: string;
}

export interface TrueSelfTemplateHealthyHabit {
    id?:string;
    trueSelfTemplateId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    copied?:any[];
    user?: string;
}
export interface TrueSelfTemplateThinking {
    id?:string;
    trueSelfTemplateId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    copied?:any[];
    user?: string;
}
export interface TrueSelfTemplateSkill {
    trueSelfTemplateId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    copied?:any[];
    user?: string;
}

export enum TrueSelfTemplateType {
    GlobalPrinciple = 1,
    PersonalityDevelop = 2,
    HealthyHabit = 3,
    Thinking = 4,
    Skill = 5,
}