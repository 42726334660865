import { Dir } from '@angular/cdk/bidi';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeAr from '@angular/common/locales/ar-AE';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { appInjector } from './appInjector';
import { msalConfig } from './modules/auth/auth.config';
import { AuthInterceptor } from './modules/auth/auth.interceptor';
import { IconsModule } from './icons.module';

registerLocaleData(localeAr);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        LayoutModule,
        IconsModule,

        // Initiate the MSAL library with the MSAL configuration object
        MsalModule.forRoot(new PublicClientApplication(msalConfig),
            {
                // The routing guard configuration. 
                interactionType: InteractionType.Redirect,
                // authRequest: {
                //     scopes: protectedResources.owxApi.scopes
                // }
            },
            {
            // MSAL interceptor configuration.
            // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([])
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        Dir,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
          },
          MsalGuard
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ]
})
export class AppModule {
    constructor(private inj: Injector) {
        appInjector(inj);
    }
}
