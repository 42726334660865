import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { AiAssistantModule } from 'app/modules/ai-assistant/ai-assistant.module';
import { AuthService } from 'app/modules/auth/auth.service';
import { SharedModule } from 'app/shared/shared.module';
import { UserService } from './models/user/user.service';
import { OwxLayoutComponent } from './owx-layout/owx-layout.component';
import { UserComponent } from './user/user.component';
import { ResizableModule } from 'angular-resizable-element';
@NgModule({
    declarations: [
        LayoutComponent,
        OwxLayoutComponent,
        UserComponent
    ],
    imports: [
        SharedModule,
        HttpClientModule,
        RouterModule,
        AiAssistantModule,
        ResizableModule
    ],
    exports: [
        LayoutComponent,
        OwxLayoutComponent,
        UserComponent
    ],
    providers: [
        AuthService,
        UserService
    ]
})
export class LayoutModule {
}
