import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
import { VisionBoard, VisionBoardInspiration, VisionBoardVision, VisionUserLinkedAchieve } from "../models/vision-board";
import { OwxApiService } from "app/shared/services/generics/owx-api-service";
import { blobKey } from "app/contants";

@Injectable({
    providedIn: 'root',
})
export class VisionBoardDataService extends OwxApiService {
    private openCreateListDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _inspirations: BehaviorSubject<VisionBoardInspiration[]> = new BehaviorSubject<VisionBoardInspiration[]>(null);
    private _visions: BehaviorSubject<VisionBoardVision[]> = new BehaviorSubject<VisionBoardVision[]>(null);

    get inspirations$(): Observable<VisionBoardInspiration[]> {
        return this._inspirations.asObservable();
    }

    get visions$(): Observable<VisionBoardVision[]> {
        return this._visions.asObservable();
    }

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getOpenCreateListDialog(): Observable<any>{
        return this.openCreateListDialog.asObservable();
    }

    setOpenCreateListDialog(value){
        this.openCreateListDialog.next(value);
    }

    createVisionBoard(visionBoard: VisionBoard): Observable<any> {
        return this.post("VisionUser/Header", visionBoard);
    }

    getVisionBoardByUser(userId: string): Observable<any> {
        return this.get("VisionUser/ByUser/" + userId);
    }

    getVisionBoardInspiration(visionBoardId: string): Observable<VisionBoardInspiration[]> {
        return this.get<VisionBoardInspiration[]>("VisionUser/get-inspirations?visionUserId=" + visionBoardId).pipe(
            tap(res => {
                res?.forEach(e => {
                    e.imageUrl = e.imageUrl + blobKey;
                });
                this._inspirations.next(res);
                return res;
            })
        );
    }

    createVisionBoardInspiration(visionBoardInspiration: VisionBoardInspiration) {
        return this.post("VisionUser/create-inspiration", visionBoardInspiration).pipe(
            switchMap(res => {
                return this.getVisionBoardInspiration(visionBoardInspiration.visionUserId);
            })
        );
    }

    updateVisionBoardInspiration(visionBoardInspiration: VisionBoardInspiration) {
        return this.put("VisionUser/update-inspiration", visionBoardInspiration).pipe(
            switchMap(res => {
                return this.getVisionBoardInspiration(visionBoardInspiration.visionUserId);
            })
        );
    }

    deleteVisionBoardInspiration(visionBoardId: string, inspirationId: string) {
        return this.delete("VisionUser/delete-inspiration?visionUserId=" + visionBoardId + "&inspirationId=" + inspirationId).pipe(
            switchMap(res => {
                return this.getVisionBoardInspiration(visionBoardId);
            })
        );
    }

    createVisionBoardVision(visionBoardVision: VisionBoardVision) {
        return this.post("VisionUser/create-vision", visionBoardVision).pipe(
            switchMap(res => {
                return this.getVisionBoardVisions(visionBoardVision.visionUserId);
            })
        );
    }

    updateVisionBoardVision(visionBoardVision: VisionBoardVision) {
        return this.put("VisionUser/update-vision", visionBoardVision).pipe(
            switchMap(res => {
                return this.getVisionBoardVisions(visionBoardVision.visionUserId);
            })
        );
    }

    getVisionBoardVisions(visionBoardId: string): Observable<VisionBoardVision[]> {
        return this.get<VisionBoardVision[]>("VisionUser/get-visions?visionUserId=" + visionBoardId).pipe(
            tap(res => {
                res?.forEach(e => {
                    e.imageUrl = e.imageUrl + blobKey;
                });
                this._visions.next(res);
                return res;
            })
        );
    }

    deleteVisionBoardVision(visionBoardId: string, visionId: string) {
        return this.delete("VisionUser/delete-vision?visionUserId=" + visionBoardId + "&visionId=" + visionId).pipe(
            switchMap(res => {
                return this.getVisionBoardVisions(visionBoardId);
            })
        );
    }

    getVisionBoardVisionLinkedAchieves(visionBoardId: string, visionId: string): Observable<VisionUserLinkedAchieve[]> {
        return this.get<VisionUserLinkedAchieve[]>("VisionUser/vision-linkedin-achieves?visionUserId=" + visionBoardId + "&visionId=" + visionId);
    }

    getVisionBoardInspirationLinkedAchieves(visionBoardId: string, inspirationId: string): Observable<VisionUserLinkedAchieve[]> {
        return this.get<VisionUserLinkedAchieve[]>("VisionUser/inspiration-linkedin-achieves?visionUserId=" + visionBoardId + "&inspirationId=" + inspirationId);
    }

    createVisionBoardVisionLinkedAchieve(visionUserLinkedAchieve: VisionUserLinkedAchieve) {
        return this.post("VisionUser/vision-linkedin-achieve", visionUserLinkedAchieve).pipe(
            switchMap(res => {
                return this.getVisionBoardVisions(visionUserLinkedAchieve.visionUserId);
            })
        );
    }

    createVisionBoardInspirationLinkedAchieve(visionUserLinkedAchieve: VisionUserLinkedAchieve) {
        return this.post("VisionUser/inspiration-linkedin-achieve", visionUserLinkedAchieve).pipe(
            switchMap(res => {
                return this.getVisionBoardInspiration(visionUserLinkedAchieve.visionUserId);
            })
        );
    }

    deleteVisionBoardVisionLinkedAchieves(visionBoardId: string, visionId: string, linkedAchieveId: string): Observable<any> {
        return this.delete("VisionUser/vision-linkedin-achieve?visionUserId=" + visionBoardId + "&visionId=" + visionId + "&linkedInAchieveId=" + linkedAchieveId);
    }

    deleteVisionBoardInspirationLinkedAchieves(visionBoardId: string, inspirationId: string, linkedAchieveId: string): Observable<any> {
        return this.delete("VisionUser/inspiration-linkedin-achieve?visionUserId=" + visionBoardId + "&inspirationId=" + inspirationId + "&linkedInAchieveId=" + linkedAchieveId);
    }
}