import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OwxApiService } from "app/shared/services/generics/owx-api-service";
import { BehaviorSubject, Observable, switchMap, tap } from "rxjs";
import { TrueSelf, TrueSelfGlobalPrinciple, TrueSelfHealthyHabit, TrueSelfLinkedAchieve, TrueSelfPersonalityDevelop, TrueSelfSkill, TrueSelfThinking } from "../models/true-self-board";

@Injectable({
    providedIn: 'root',
})
export class TrueSelfBoardDataService extends OwxApiService {
    private openCreateListDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _trueSelfGlobalPrinciples: BehaviorSubject<TrueSelfGlobalPrinciple[]> = new BehaviorSubject<TrueSelfGlobalPrinciple[]>(null);
    private _thinking: BehaviorSubject<TrueSelfThinking[]> = new BehaviorSubject<TrueSelfThinking[]>(null);
    private _skills: BehaviorSubject<TrueSelfSkill[]> = new BehaviorSubject<TrueSelfSkill[]>(null);
    private _healthyHabits: BehaviorSubject<TrueSelfHealthyHabit[]> = new BehaviorSubject<TrueSelfHealthyHabit[]>(null);
    private _personalityDevelop: BehaviorSubject<TrueSelfPersonalityDevelop[]> = new BehaviorSubject<TrueSelfPersonalityDevelop[]>(null);

    get trueSelfGlobalPrinciples$(): Observable<TrueSelfGlobalPrinciple[]> {
        return this._trueSelfGlobalPrinciples.asObservable();
    }

    get thinking$(): Observable<TrueSelfThinking[]> {
        return this._thinking.asObservable();
    }

    get skills$(): Observable<TrueSelfSkill[]> {
        return this._skills.asObservable();
    }

    get healthyHabits$(): Observable<TrueSelfHealthyHabit[]> {
        return this._healthyHabits.asObservable();
    }

    get personalityDevelop$(): Observable<TrueSelfPersonalityDevelop[]> {
        return this._personalityDevelop.asObservable();
    }

    getOpenCreateListDialog(): Observable<any>{
        return this.openCreateListDialog.asObservable();
    }

    setOpenCreateListDialog(value){
        this.openCreateListDialog.next(value);
    }

    createTrueSelf(trueSelf: TrueSelf){
        return this.post("TrueSelf/Header",trueSelf);
    }

    getTrueSelfByUser(userId: string){
        return this.get("TrueSelf/ByUser/" + userId);
    }

    getTrueSelfTemlates(){
        return this.get("TrueSelfTemplate");
    }

    getTrueSelfGlobalPrinciples(trueSelfBoardId: string): Observable<TrueSelfGlobalPrinciple[]> {
        return this.get<TrueSelfGlobalPrinciple[]>(`TrueSelf/get-global-principles?TrueSelfId=${trueSelfBoardId}`).pipe(
            tap(res => {
                this._trueSelfGlobalPrinciples.next(res);
                return res;
            })
        );
    }

    createTrueSelfGlobalPrinciple(TrueSelfGlobalPrinciple: TrueSelfGlobalPrinciple): Observable<any> {
        return this.post("TrueSelf/create-global-principle", TrueSelfGlobalPrinciple).pipe(
            switchMap(res => {
                return this.getTrueSelfGlobalPrinciples(TrueSelfGlobalPrinciple.trueSelfId);
            })
        );
    }

    updateTrueSelfGlobalPrinciple(TrueSelfGlobalPrinciple: TrueSelfGlobalPrinciple): Observable<any> {
        return this.put("TrueSelf/update-global-principle", TrueSelfGlobalPrinciple).pipe(
            switchMap(res => {
                return this.getTrueSelfGlobalPrinciples(TrueSelfGlobalPrinciple.trueSelfId);
            })
        );
    }

    deleteTrueSelfGlobalPrinciple(trueSelfBoardId: string, TrueSelfGlobalPrincipleId: string): Observable<any> {
        return this.delete(`TrueSelf/delete-global-principle?TrueSelfId=${trueSelfBoardId}&globalPrincipleId=${TrueSelfGlobalPrincipleId}`).pipe(
            switchMap(res => {
                return this.getTrueSelfGlobalPrinciples(trueSelfBoardId);
            })
        );
    }

    getSkills(trueSelfBoardId: string): Observable<TrueSelfSkill[]> {
        return this.get<TrueSelfSkill[]>(`TrueSelf/get-skills?TrueSelfId=${trueSelfBoardId}`).pipe(
            tap(res => {
                this._skills.next(res);
                return res;
            })
        );
    }

    createSkill(skill: TrueSelfSkill): Observable<any> {
        return this.post("TrueSelf/create-skill", skill).pipe(
            switchMap(res => {
                return this.getSkills(skill.trueSelfId);
            })
        );
    }

    updateSkill(skill: TrueSelfSkill): Observable<any> {
        return this.put("TrueSelf/update-skill", skill).pipe(
            switchMap(res => {
                return this.getSkills(skill.trueSelfId);
            })
        );
    }

    deleteSkill(trueSelfBoardId: string, skillId: string): Observable<any> {
        return this.delete(`TrueSelf/delete-skill?TrueSelfId=${trueSelfBoardId}&skillId=${skillId}`).pipe(
            switchMap(res => {
                return this.getSkills(trueSelfBoardId);
            })
        );
    }

    getPersonalityDevelops(trueSelfBoardId: string): Observable<TrueSelfPersonalityDevelop[]> {
        return this.get<TrueSelfPersonalityDevelop[]>(`TrueSelf/get-personality-develops?TrueSelfId=${trueSelfBoardId}`).pipe(
            tap(res => {
                this._personalityDevelop.next(res);
                return res;
            })
        );
    }

    createPersonalityDevelop(personalityDevelop: TrueSelfPersonalityDevelop): Observable<any> {
        return this.post("TrueSelf/create-personality-develop", personalityDevelop).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(personalityDevelop.trueSelfId);
            })
        );
    }

    updatePersonalityDevelop(personalityDevelop: TrueSelfPersonalityDevelop): Observable<any> {
        return this.put("TrueSelf/update-personality-develop", personalityDevelop).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(personalityDevelop.trueSelfId);
            })
        );
    }

    deletePersonalityDevelop(trueSelfBoardId: string, personalityDevelopId: string): Observable<any> {
        return this.delete(`TrueSelf/delete-personality-develop?TrueSelfId=${trueSelfBoardId}&personalityDevelopId=${personalityDevelopId}`).pipe(
            switchMap(res => {
                return this.getPersonalityDevelops(trueSelfBoardId);
            })
        );
    }

    getThinkings(trueSelfBoardId: string): Observable<TrueSelfThinking[]> {
        return this.get<TrueSelfThinking[]>(`TrueSelf/get-thinkings?TrueSelfId=${trueSelfBoardId}`).pipe(
            tap(res => {
                this._thinking.next(res);
                return res;
            })
        );
    }

    createThinking(thinking: TrueSelfThinking): Observable<any> {
        return this.post("TrueSelf/create-thinking", thinking).pipe(
            switchMap(res => {
                return this.getThinkings(thinking.trueSelfId);
            })
        );
    }

    updateThinking(thinking: TrueSelfThinking): Observable<any> {
        return this.put("TrueSelf/update-thinking", thinking).pipe(
            switchMap(res => {
                return this.getThinkings(thinking.trueSelfId);
            })
        );
    }

    deleteThinking(trueSelfBoardId: string, thinkingId: string): Observable<any> {
        return this.delete(`TrueSelf/delete-thinking?TrueSelfId=${trueSelfBoardId}&thinkingId=${thinkingId}`).pipe(
            switchMap(res => {
                return this.getThinkings(trueSelfBoardId);
            })
        );
    }

    getHealthyHabits(trueSelfBoardId: string): Observable<TrueSelfHealthyHabit[]> {
        return this.get<TrueSelfHealthyHabit[]>(`TrueSelf/get-healthy-habits?TrueSelfId=${trueSelfBoardId}`).pipe(
            tap(res => {
                this._healthyHabits.next(res);
                return res;
            })
        );
    }

    createHealthyHabit(healthyHabit: TrueSelfHealthyHabit): Observable<any> {
        return this.post("TrueSelf/create-healthy-habit", healthyHabit).pipe(
            switchMap(res => {
                return this.getHealthyHabits(healthyHabit.trueSelfId);
            })
        );
    }

    updateHealthyHabit(healthyHabit: TrueSelfHealthyHabit): Observable<any> {
        return this.put("TrueSelf/update-healthy-habit", healthyHabit).pipe(
            switchMap(res => {
                return this.getHealthyHabits(healthyHabit.trueSelfId);
            })
        );
    }

    deleteHealthyHabit(trueSelfBoardId: string, healthyHabitId: string): Observable<any> {
        return this.delete(`TrueSelf/delete-healthy-habit?TrueSelfId=${trueSelfBoardId}&healthyHabitId=${healthyHabitId}`).pipe(
            switchMap(res => {
                return this.getHealthyHabits(trueSelfBoardId);
            })
        );
    }

    
    getGlobalPrincipleLinkedAchieves(trueSelfBoardId: string, globalPrincipleId: string): Observable<TrueSelfLinkedAchieve[]> {
        return this.get<TrueSelfLinkedAchieve[]>(`TrueSelf/global-principle-linkedin-achieves?trueSelfId=${trueSelfBoardId}&globalprincipleId=${globalPrincipleId}`);
    }

    getPersonalityDevelopLinkedAchieves(trueSelfBoardId: string, personalityDevelopId: string): Observable<TrueSelfLinkedAchieve[]> {
        return this.get<TrueSelfLinkedAchieve[]>(`TrueSelf/personality-develop-linkedin-achieves?trueSelfId=${trueSelfBoardId}&personalitydevelopId=${personalityDevelopId}`);
    }

    getHealthyHabitLinkedAchieves(trueSelfBoardId: string, healthyHabitId: string): Observable<TrueSelfLinkedAchieve[]> {
        return this.get<TrueSelfLinkedAchieve[]>(`TrueSelf/healthy-habit-linkedin-achieves?trueSelfId=${trueSelfBoardId}&healthyhabitId=${healthyHabitId}`);
    }

    getThinkingLinkedAchieves(trueSelfBoardId: string, thinkingId: string): Observable<TrueSelfLinkedAchieve[]> {
        return this.get<TrueSelfLinkedAchieve[]>(`TrueSelf/thinking-linkedin-achieves?trueSelfId=${trueSelfBoardId}&thinkingId=${thinkingId}`);
    }

    getSkillLinkedAchieves(trueSelfBoardId: string, skillId: string): Observable<TrueSelfLinkedAchieve[]> {
        return this.get<TrueSelfLinkedAchieve[]>(`TrueSelf/skill-linkedin-achieves?trueSelfId=${trueSelfBoardId}&skillId=${skillId}`);
    }

    createGlobalPrincipleLinkedAchieve(linkedAchieve: TrueSelfLinkedAchieve): Observable<any> {
        return this.post('TrueSelf/global-principle-linkedin-achieve', linkedAchieve).pipe(
            switchMap(res => {
                return this.getGlobalPrincipleLinkedAchieves(linkedAchieve.trueSelfId, linkedAchieve.globalPrincipleId);
            })
        );
    }

    createPersonalityDevelopLinkedAchieve(linkedAchieve: TrueSelfLinkedAchieve): Observable<any> {
        return this.post('TrueSelf/personality-develop-linkedin-achieve', linkedAchieve).pipe(
            switchMap(res => {
                return this.getPersonalityDevelopLinkedAchieves(linkedAchieve.trueSelfId, linkedAchieve.personalityDevelopId);
            })
        );
    }

    createHealthyHabitLinkedAchieve(linkedAchieve: TrueSelfLinkedAchieve): Observable<any> {
        return this.post('TrueSelf/healthy-habit-linkedin-achieve', linkedAchieve).pipe(
            switchMap(res => {
                return this.getHealthyHabitLinkedAchieves(linkedAchieve.trueSelfId, linkedAchieve.healthyHabitId);
            })
        );
    }

    createThinkingLinkedAchieve(linkedAchieve: TrueSelfLinkedAchieve): Observable<any> {
        return this.post('TrueSelf/thinking-linkedin-achieve', linkedAchieve).pipe(
            switchMap(res => {
                return this.getThinkingLinkedAchieves(linkedAchieve.trueSelfId, linkedAchieve.thinkingId);
            })
        );
    }

    createSkillLinkedAchieve(linkedAchieve: TrueSelfLinkedAchieve): Observable<any> {
        return this.post('TrueSelf/skill-linkedin-achieve', linkedAchieve).pipe(
            switchMap(res => {
                return this.getSkillLinkedAchieves(linkedAchieve.trueSelfId, linkedAchieve.skillId);
            })
        );
    }

    deleteGlobalPrincipleLinkedAchieves(trueSelfBoardId: string, globalPrincipleId: string, linkedAchieveId:string): Observable<any> {
        return this.delete(`TrueSelf/global-principle-linkedin-achieve?trueSelfId=${trueSelfBoardId}&globalprincipleId=${globalPrincipleId}&linkedInAchieveId=${linkedAchieveId}`);
    }

    deletePersonalityDevelopLinkedAchieves(trueSelfBoardId: string, personalityDevelopId: string, linkedAchieveId:string): Observable<any> {
        return this.delete(`TrueSelf/personality-develop-linkedin-achieve?trueSelfId=${trueSelfBoardId}&personalitydevelopId=${personalityDevelopId}&linkedInAchieveId=${linkedAchieveId}`);
    }

    deleteHealthyHabitLinkedAchieves(trueSelfBoardId: string, healthyHabitId: string, linkedAchieveId:string): Observable<any> {
        return this.delete(`TrueSelf/healthy-habit-linkedin-achieve?trueSelfId=${trueSelfBoardId}&healthyhabitId=${healthyHabitId}&linkedInAchieveId=${linkedAchieveId}`);
    }

    deleteThinkingLinkedAchieves(trueSelfBoardId: string, thinkingId: string, linkedAchieveId:string): Observable<any> {
        return this.delete(`TrueSelf/thinking-linkedin-achieve?trueSelfId=${trueSelfBoardId}&thinkingId=${thinkingId}&linkedInAchieveId=${linkedAchieveId}`);
    }

    deleteSkillLinkedAchieves(trueSelfBoardId: string, skillId: string, linkedAchieveId:string): Observable<any> {
        return this.delete(`TrueSelf/skill-linkedin-achieve?trueSelfId=${trueSelfBoardId}&skillId=${skillId}&linkedInAchieveId=${linkedAchieveId}`);
    }
}