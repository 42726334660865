<div class="flex flex-col flex-auto w-full min-w-0">
    <div
        class="flex header-t justify-between flex-row p-2 md:p-3 border-b-2 border-[#DCDCDC] dark:border-b bg-card dark:bg-transparent">
        <div class="d-flex">
            <div class="mx-2 my-auto d-flex align-items-center ">
                <!-- <img class="img-logo" src="assets/logo/oneworld_logo.png" alt="OWX logo" /> -->
                <img class="img-logo" src="assets/logo/logo1.png" alt="" class="h-5" />
                <!-- <span>Beta</span> -->
            </div>

            <div class="hidden md:flex space-x-4">
                <div class="flex items-center space-x-4">
                    <a class="nav-link font-bold ms-3" (click)="showFiller = true" [routerLink]="['/home']"
                        routerLinkActive="active">
                        <mat-icon>home</mat-icon>
                        <span>Home</span>
                    </a>
                    <a class="nav-link font-bold" (click)="showFiller = true" [routerLink]="['/my-life/achieve/board']"
                        routerLinkActive="active">
                        <!-- <mat-icon>dashboard</mat-icon> -->
                        <mat-icon>settings_accessibility</mat-icon>
                        <span>My life</span></a>
                    <a class="nav-link font-bold" (click)="showFiller = true" [routerLink]="['/community']"
                        routerLinkActive="active">
                        <!-- <mat-icon>donut_small</mat-icon> -->
                        <mat-icon>diversity_3</mat-icon>
                        <!-- <span>Contribute</span></a> -->
                        <span>Expand</span></a>
                    <a class="nav-link font-bold" (click)="showFiller = true" [routerLink]="['/connect']"
                        routerLinkActive="active">
                        <mat-icon>share</mat-icon>
                        <span>Connect</span></a>

                    <a class="nav-link font-bold" (click)="showFiller = true" [routerLink]="['/explore']"
                        routerLinkActive="active">
                        <mat-icon>explore</mat-icon>
                        <span>Explore</span></a>
                </div>
            </div>
        </div>
        <div class="hidden md:flex align-items-center me-3">
            <a class="nav-link ai-btn font-bold pointer d-flex  px-3 py-2 me-4" 
            
            (click)="rightMenuToggle()" routerLinkActive="active">
                <!-- <mat-icon>live_help</mat-icon> -->
                <mat-icon>blur_circular</mat-icon>
                <span>Isha AI</span></a>
                <div class="border-line"></div>
            <a class="nav-link font-bold mx-3" [routerLink]="['/connect/chat']" routerLinkActive="active">
                <!-- <mat-icon >chat_bubble_outline</mat-icon> -->
                <mat-icon >inbox</mat-icon>
                <span>Inbox</span></a>

            <a class="nav-link font-bold me-3" routerLink="/notification" routerLinkActive="active">
                <mat-icon >notifications</mat-icon>
                <span>Notification</span></a>
           
                <user (close)="isHamburgerMenuOpen=false">
                </user>
            
        </div>

        <button type="button" (click)="toggleHamburgerMenu()"
            class="md:hidden btn text-2xl focus:outline-none ">
            <mat-icon class="text-red-500" [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
    </div>

    <div class="flex flex-auto flex-row">

        <mat-drawer-container *ngIf="page" class="example-container">
            <mat-drawer #drawer *ngIf="isDesktop" class="example-sidenav desktop-sidebar" [ngClass]="{
                    'drawer-true': showFiller == true,
                    'drawer-false': showFiller == false
                }" opened="true" mode="side">
                <button type="button" [ngClass]="{
                        'toggle-bt-open': showFiller == true,
                        'toggle-bt': showFiller == false
                    }" class="mx-2" (click)="changeSidebar()" mat-button>
                    <mat-icon  class="menu0icon ps-3" *ngIf="showFiller">arrow_back_ios</mat-icon>
                    <mat-icon class="menu0icon" *ngIf="!showFiller">arrow_forward_ios</mat-icon>
                </button>
                <div class="p-2">
                    <ul class="navbar-ul">
                        <ng-container *ngFor="let item of menu[page]">
                            <li class="nav-item">
                                <a (click)="item.expand = !item.expand" *ngIf="item.submenu"
                                    class="nav-link p-0 text-center d-flex" placement="top" style="border-bottom: none"
                                    [ngClass]="{
                                        'a-active':
                                            checkCurrentRoute2('/my-life/'),
                                        'd-flex': !showFiller
                                    }" >
                                     <mat-icon matTooltip="{{ item.title }}"
                                     *ngIf="!showFiller"
                                     >{{
                                         item.icon
                                     }}</mat-icon>
                                      <mat-icon 
                                      *ngIf="showFiller"
                                      >{{
                                         item.icon
                                     }}</mat-icon>
                                    <div class="d-flex justify-content-center">
                                        <span *ngIf="showFiller">{{
                                            item.title
                                            }}</span>
                                        <ng-container>
                                            <mat-icon class="mx-1" *ngIf="item.expand">expand_more</mat-icon>
                                            <mat-icon class="mx-1" *ngIf="!item.expand">chevron_right</mat-icon>
                                        </ng-container>
                                    </div>
                                </a>
                                <a *ngIf="!item.submenu" class="nav-link p-0 text-center" [routerLink]="[item.route]"
                                    [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="a-active"
                                    placement="top" ngbTooltip="TrueSelf">
                                    <mat-icon matTooltip="{{ item.title }}"
                                    *ngIf="!showFiller"
                                    >{{
                                        item.icon
                                    }}</mat-icon>
                                     <mat-icon 
                                     *ngIf="showFiller"
                                     >{{
                                        item.icon
                                    }}</mat-icon>
                                    <span *ngIf="showFiller">{{
                                        item.title
                                        }}</span></a>
                            </li>
                            <li class="nav-item mt-2" *ngIf="
                                    item.submenu &&
                                    item.submenu.length > 0 &&
                                    item.expand
                                ">
                                <ng-container *ngFor="let subItem of item.submenu">
                                    <a class="nav-link justify-content-center d-flex align-items-center"
                                        [routerLink]="[subItem.route]" routerLinkActive="a-active"><mat-icon
                                            class="me-2">{{
                                            subItem.icon
                                            }}</mat-icon><span *ngIf="showFiller">{{
                                            subItem.title
                                            }}</span></a>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </mat-drawer>
            <mat-drawer #drawer1 *ngIf="!isDesktop" class="example-sidenav mobile-sidebar" opened="false" mode="side">
                <div class="p-2">
                    <ul class="navbar-ul">
                        <ng-container *ngFor="let item of menu[page]">
                            <li class="nav-item">
                                <a (click)="item.expand = !item.expand" *ngIf="item.submenu"
                                    class="nav-link p-0 text-center" style="border-bottom: none" [ngClass]="
                                        checkCurrentRoute2() ? 'a-active' : ''
                                    " placement="top" ngbTooltip="TrueSelf">
                                    <mat-icon class="me-2">{{
                                        item.icon
                                        }}</mat-icon>
                                    <div class="d-flex justify-content-center">
                                        <span>{{ item.title }}</span>
                                        <ng-container>
                                            <mat-icon class="mx-1" *ngIf="item.expand">expand_more</mat-icon>
                                            <mat-icon class="mx-1" *ngIf="!item.expand">chevron_right</mat-icon>
                                        </ng-container>
                                    </div>
                                </a>
                                <a *ngIf="!item.submenu" class="nav-link p-0 text-center" (click)="drawer1.toggle()"
                                    [routerLink]="[item.route]" [routerLinkActiveOptions]="{ exact: true }"
                                    routerLinkActive="a-active" placement="top" ngbTooltip="TrueSelf">
                                    <mat-icon class="me-2">{{
                                        item.icon
                                        }}</mat-icon>
                                    <span>{{ item.title }}</span></a>
                            </li>
                            <li class="nav-item mt-2" *ngIf="
                                    item.submenu &&
                                    item.submenu.length > 0 &&
                                    item.expand
                                ">
                                <ng-container *ngFor="let subItem of item.submenu">
                                    <a class="nav-link justify-content-center d-flex align-items-center"
                                        (click)="drawer1.toggle()" [routerLink]="[subItem.route]"
                                        routerLinkActive="a-active">
                                        <mat-icon matTooltip="{{ subItem.title }}" class="me-2" *ngIf="!showFiller">{{
                                            subItem.icon}}</mat-icon>
                                         <mat-icon  class="me-2" *ngIf="showFiller">{{
                                            subItem.icon
                                        }}</mat-icon>
                                         <span *ngIf="showFiller">{{ subItem.title }}</span></a>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </mat-drawer>
            <div class="example-sidenav-content bg-light" [ngClass]="{
                    'content-expand': showFiller == true,
                    'content-noexpand': showFiller == false
                }">
                <button type="button" class="d-block d-md-none d-lg-none" style="z-index: 99;" [ngClass]="{
                        'toggle-bt-open': showFiller == true,
                        'toggle-bt': showFiller == false
                    }" mat-button (click)="drawer1.toggle()">
                    <mat-icon  class="text-red-500 menu0icon ps-3" *ngIf="isDrawerOpen()">arrow_back_ios</mat-icon> 
                    <mat-icon class="text-red-500 menu0icon" *ngIf="!isDrawerOpen()" >arrow_forward_ios</mat-icon>
                </button>
            </div>
        </mat-drawer-container>

        <div class="panel-content" 
        [ngClass]="{'p-0': currentRoute == '/connect/chat'}"
        >
         <!--    <mat-card class="panel-card mt-4" style="width: 100%"> -->
                <router-outlet *ngIf="true"></router-outlet>
          <!--   </mat-card> -->
        </div>

        <div class="r-panel d-lg-block d-none" mwlResizable *ngIf="rightPanel"
        [enableGhostResize]="true"
        [ngStyle]="{ 'width.px': width }"
        (resizeEnd)="onResizeEnd($event)">
            <div *ngIf="rightPanel"
            class="resize-handle-left "
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
          >
          <mat-icon>drag_indicator</mat-icon>
        </div>
            <div class="right-panel"  [ngStyle]="{ 'width.px': width }" >
                <div class="head p-2">
                    <div class="d-flex">
                        <mat-icon class="text-red-500 me-2">chat_bubble_outline</mat-icon>
                        Chat
                    </div>
                    <div>
                        <button (click)="showChatHistory()" class="border-none bg-blue-50">
                            <mat-icon class="text-red-500 me-2">more_vert</mat-icon>
                        </button>
                        <button type="button" (click)="rightMenuToggle()" class="border-none bg-blue-50">
                            <mat-icon class="text-red-500">close</mat-icon>
                        </button>
                    </div>
                </div>
                <ai-chat></ai-chat>
            </div>
        </div>

        <!--  MOBILE VIEW -->
<div class="r-panel d-lg-none d-block"  
>
    <div class="right-panel" *ngIf="rightPanel" >
        <div class="head p-2">
            <div class="d-flex">
                <mat-icon class="text-red-500 me-2">
                    chat_bubble_outline</mat-icon
                >
                Chat
            </div>
            <div>
                <mat-icon class="text-red-500 me-2">more_vert</mat-icon>
                <button type="button" class="btn" (click)="rightMenuToggle()">
                    <mat-icon class="text-red-500">close</mat-icon>
                </button>
            </div>
        </div>
        <!-- <ai-assistant></ai-assistant> -->
        <ai-chat></ai-chat>
    </div>
</div>
    </div>
</div>

<div id="menuContent" class="md:hidden fixed left-0 right-0 bottom-0 bg-gray-100 p-4 space-y-4"
    [class.hidden]="!isHamburgerMenuOpen">
    <div>
       <!--  <div>
            <div class="flex items-center">
                <input type="text"
                    class="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-full leading-5 bg-white focus:outline-none focus:border-red-500 focus:ring focus:ring-red-200 transition duration-150 ease-in-out shadow-md"
                    placeholder="What you want to achieve" />

                <div class="bg-primary rounded-full p-1 w-10 ml-[-41px]">
                    <mat-icon class="text-white p-1">search</mat-icon>
                </div>
            </div>
        </div> -->

        <div class="mt-3">
            <div>
                <user (close)="isHamburgerMenuOpen=false" (isHamburgerMenuOpen)="closeMenu($event)"></user>
                User
            </div>
        </div>

        <div class="d-block mt-3 align-items-center my-auto justify-content-between">
            <button type="button" mat-button class="mt-3 mat-icon-btn primary-mat-btn w-full" routerLink="/connect/chat" (click)="toggleHamburgerMenu()">
                <mat-icon matTooltip="Messages">chat_bubble_outline</mat-icon>
                Inbox
            </button>

            <button type="button" routerLink="/notification" (click)="toggleHamburgerMenu()" mat-button  class="mt-3 mat-icon-btn primary-mat-btn w-full">
                <mat-icon matTooltip="Notifications">notifications</mat-icon>
                    Notifications
                
            </button>
            <button type="button" mat-button class="mt-3 w-full mat-icon-btn primary-mat-btn"  (click)="rightMenuToggle()">
                  <!-- <mat-icon> live_help</mat-icon> -->
                  <mat-icon>blur_circular</mat-icon>
                    Isha AI
                
            </button>
           
        </div>
    </div>

    <a class="nav-link font-bold" [routerLink]="['/home']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"><mat-icon>home</mat-icon>Home</a>
    <a class="nav-link font-bold" [routerLink]="['/my-life/achieve/board']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"><mat-icon>settings_accessibility</mat-icon>My life</a>
    <!-- <a class="nav-link font-bold" [routerLink]="['/community']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"> <mat-icon>donut_small</mat-icon>Contribute</a> -->
        <a class="nav-link font-bold" [routerLink]="['/community']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"> <mat-icon>donut_small</mat-icon>Expand</a>
    <a class="nav-link font-bold" [routerLink]="['/connect']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"><mat-icon>share</mat-icon>Connect</a>
    <a class="nav-link font-bold" [routerLink]="['/explore']" (click)="toggleHamburgerMenu()"
        routerLinkActive="active"><mat-icon>explore</mat-icon>Explore</a>
</div>

<div *ngIf="!rightPanel" class="floating-icon-container" (click)="rightMenuToggle()">
    <button matTooltip="Isha AI Assistant" mat-icon-button class="floating-icon">
        <!-- <mat-icon class="white"> live_help</mat-icon> -->
        <mat-icon class="white">blur_circular</mat-icon>
    </button>
</div>