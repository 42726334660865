import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'app/layout/models/user/user.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private url = `${environment.owxSectionApiUrl}`;
    user: Observable<{ id: any; name: string; email: any; avatar: any; phone: any; location: any; passwordSet: any; status: string; }>;

    constructor(private http: HttpClient,
        private _userService: UserService,
        private msalService: MsalService) {
    }
}
