import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, catchError, of } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ChatAiApiService {
    private apiUrl = environment.chatAiApiUrl;

    constructor(private http: HttpClient) {}
  
    get<T>(endpoint: string, params?: HttpParams): Observable<any> {
      const url = `${this.apiUrl}/${endpoint}`;
  
      return this.http.get<T>(url, { params }).pipe(
        catchError((error) => {
          return of({});
        })
      );
    }
  
    post(endpoint: string, body: any, headers?: HttpHeaders): Observable<any> {
      const url = `${this.apiUrl}/${endpoint}`;
  
      return this.http.post<any>(url, body, { headers }).pipe(
        catchError((error) => {
          return of({});
        })
      );
    }
  
    put(endpoint: string, body: any, headers?: HttpHeaders): Observable<any> {
      const url = `${this.apiUrl}/${endpoint}`;
  
      return this.http.put<any>(url, body, { headers }).pipe(
        catchError((error) => {
          return of({});
        })
      );
    }
  
    delete(endpoint: string, params?: HttpParams): Observable<any> {
      const url = `${this.apiUrl}/${endpoint}`;
  
      return this.http.delete<any>(url, { params }).pipe(
        catchError((error) => {
          return of({});
        })
      );
    }
}