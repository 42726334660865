<div class="d-flex justify-between align-items-center">
    <h2 mat-dialog-title class="mb-0 fw-600 text-muted-8">{{this.data?.type}} Image Upload</h2>
    <mat-icon mat-dialog-close class="close-modal-icon text-primary cursor-pointer">close</mat-icon>
  </div>
<mat-dialog-content class="p-2">

  <div class="container">
    <div class="row">
      <owx-file-upload (uploadEmitter)="uploadFile($event)"></owx-file-upload>

    </div>
  </div>
 
</mat-dialog-content>