import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LayoutComponent } from './layout/layout.component';

export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo:'landing',
        pathMatch:'full'
    },
    {
        path: '',
        children: [
            { path: 'landing', loadChildren: () => import('app/modules/landing/landing.module').then(m => m.LandingPage) },
        ]
    },
    {
        path: '',
        children: [
            { path: 'auth', loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule) },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [MsalGuard],
        children: [
            { path: 'user-profile', loadChildren: () => import('app/modules/account/user-profile/user-profile.module').then(m => m.UserProfileModule) },
            { path: 'my-life', loadChildren: () => import('app/modules/my-life/my-life.module').then(m => m.MyLifeModule) },
            { path: 'community', loadChildren: () => import('app/modules/community/community.module').then(m => m.CommunityModule) },
            { path: 'explore', loadChildren: () => import('app/modules/explore/explore.module').then(m => m.ExploreModule) },
            { path: 'connect', loadChildren: () => import('app/modules/connect/connect.module').then(m => m.ConnectModule) },
            { path: 'notification', loadChildren: () => import('app/modules/notification/notification.module').then(m => m.NotificationModule) },
            { path: 'home', loadChildren: () => import('app/modules/home/home.module').then(m => m.HomeModule) },
            { path: 'account', loadChildren: () => import('app/modules/account/account.module').then(m => m.AccountModule) },
            { path: 'help-support', loadChildren: () => import('app/modules/account/helpsupport/helpsupport.module').then(m => m.HelpSupportModule) },
        ]
    },
];
