export interface TrueSelf {
    summaryYourself?: string,
    lifeStory?: string,
    interests?: string,
    favoriteWorld?: string,
    information?: string,
    decisions?: string,
    structure?: string,
    summaryWhatyouBelieve?: string,
    summaryHowyouExpress?: string,
    summaryHowyouKeep?: string,
    summaryWhatyouCan?: string,
    education?: string,
    keyknowledge?: string,
    experience?: string,
    keyskills?: string,
    summaryWhatyouBest?: string,
    awardsRecognition?: string,
    name: string,
    user: string
  }

export interface TrueSelfGlobalPrinciple {
    id?:string;
    trueSelfId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    linkedAchieve?: TrueSelfLinkedAchieve[];
}

export interface TrueSelfPersonalityDevelop {
    id?:string;
    trueSelfId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    linkedAchieve?: TrueSelfLinkedAchieve[];
}

export interface TrueSelfHealthyHabit {
    id?:string;
    trueSelfId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    linkedAchieve?: TrueSelfLinkedAchieve[];
}
export interface TrueSelfThinking {
    id?:string;
    trueSelfId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    linkedAchieve?: TrueSelfLinkedAchieve[];
}
export interface TrueSelfSkill {
    trueSelfId: string;
    name: string;
    type?: string;
    imageUrl?: string;
    user?: string;
    linkedAchieve?: TrueSelfLinkedAchieve[];
}

export enum TrueSelfBoardType {
    GlobalPrinciple = 1,
    PersonalityDevelop = 2,
    HealthyHabit = 3,
    Thinking = 4,
    Skill = 5,
}

export interface TrueSelfLinkedAchieve{
    trueSelfId: string,
    globalPrincipleId?: string,
    personalityDevelopId?: string,
    thinkingId?: string,
    skillId?: string,
    healthyHabitId?: string,
    title: string;
    type: string;
    achieveId?:string;
    achieveImageUrl?:string;
}
