import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AchieveStatus, AchieveVisibility, AchieveCardType, blobKey } from 'app/contants';
import { UserService } from 'app/layout/models/user/user.service';
import { Plan, Celebrate, Learn, AchieveType, AchieveHeader } from 'app/modules/my-life/achieve/models/achieve';
import { AchieveSectionDataService } from 'app/modules/my-life/achieve/services/achieve-section-data.service';
import { AchieveDataService } from 'app/modules/my-life/services/achieve-data.service';

@Component({
  selector: 'owx-achieve-detail-dialog',
  templateUrl: './achieve-detail-dialog.component.html',
  styleUrl: './achieve-detail-dialog.component.scss'
})
export class AchieveDetailDialogComponent {
  id: any;
  displayedColumns: string[] = ['activity'];
  dataSourcePlan: Plan[] = [];
  dataSourceCelebrate: Celebrate[];
  dataSourceLearn: Learn[];
  selection: any;
  cardDetail: any;
  achieveType = AchieveType;
  source: number;
  achieveHeader: AchieveHeader;
  userProfile: string;
  plansSelected: boolean = false;
  selectedPlanItems: Plan[] = [];
  achieveProgress: number = 0;
  achieveImage: string;
  achieveStatus = AchieveStatus;
  achieveVisibility = AchieveVisibility;
  achieveCardType = AchieveCardType;
  learnByCategory: any[] = [];

  constructor(private achieveDataService: AchieveDataService,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private achieveSectionDataService: AchieveSectionDataService,
    private userService: UserService,
    private dialogRef: MatDialogRef<AchieveDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data ) {
    this.source = this.achieveType.CrowdSourceJob;
    this.userProfile = this.userService.user.profileImageURL + blobKey;
  }

  ngOnInit() {
    this.id = this.data.achieveId;
    this.achieveDataService.getAchieveDetails(this.id).subscribe(res => {
      this.achieveHeader = res;
      this.achieveImage = this.achieveHeader?.achieveImageUrl + blobKey;
    });
    this.getPlans();
    this.getCelebrate();
    this.getLearns();
  }

  getImage(image: any) {
    let token = blobKey;
    let link = image + token;
    return link;
  }

  getPlans() {
    this.achieveSectionDataService.getAchievePlans(this.id).subscribe(res => {
      this.dataSourcePlan = res;
      this.dataSourcePlan.forEach(e => {
        e.planId = e.id;
      });
      this.plansSelected = this.dataSourcePlan.some(e => e.status == false) ? false : true;
    });
  }

  getCelebrate() {
    this.achieveSectionDataService.getAchieveCelebrates(this.id).subscribe(res => {
      this.dataSourceCelebrate = res;
      this.dataSourceCelebrate?.forEach(e => {
        e.userName = this.userService.user.displayName;
      })
    });
  }

  getLearns() {
    this.achieveSectionDataService.getAchieveLearns(this.id).subscribe(res => {
      this.dataSourceLearn = res;
      if (this.dataSourceLearn.length > 0) {
        this.learnByCategory = Object.entries(this.dataSourceLearn.reduce((result, item) => {
          const groupKey = item['category'];
          if (!result[groupKey]) {
            result[groupKey] = [];
          }
          result[groupKey].push(item);
          return result;
        }, {})).map(([category, values]) => ({ category, Values: values }));
      }
    });
  }

  updatelearn(learn, useful) {
    const learnData = learn;
    learnData.learnId = learn.id;
    if (useful) {
      learnData.useful = learnData.useful + 1;
    }
    else {
      learnData.notuseful = learnData.notuseful + 1;
    }

    this.achieveSectionDataService.updateAchieveLearn(learnData).subscribe();
  }

  onCancel(){
    this.dialogRef.close();
  }
}
