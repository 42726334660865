import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'app/modules/auth/auth.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService,
        private router: Router,
        private toastrService: ToastrService,
        private msalService: MsalService) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = localStorage.getItem("accessToken");
        let newReq = req.clone({
            headers: req.headers.set("Authorization", "Bearer " + accessToken)
        })

        return next.handle(newReq).pipe(

            catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    const commonToastOptions = {
                        toastClass: 'slide .inline toast-container ngx-toastr error',
                        tapToDismiss: true,
                        closeButton: true,
                        positionClass: 'toast-top-full-width'
                    };

                    switch (error.status) {
                        case 401:
                            this.msalService.logoutRedirect({
                                postLogoutRedirectUri: environment.owxWebUrl
                            });
                            localStorage.removeItem('accessToken');
                            localStorage.removeItem('user');
                            break;
                        case 400:
                            return throwError(() => error);
                            break;
                        case 500:
                        case 0:
                        case 405:
                            if (error.status !== 405 || (error.error && (error.error.code !== 503 && error.error.code !== 405))) {
                                return throwError(() => error);
                            } else if (error.status === 405) {
                                return throwError(() => error);
                            }
                            return throwError(() => error);
                        default:
                            return throwError(() => error);
                    }
                }
                return throwError(() => error);
            })
        );
    }
}