import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AchieveApiService {
  private apiUrl = environment.acheiveApiUrl;

  constructor(private http: HttpClient) {}

  get<T>(endpoint: string, params?: HttpParams): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;

    return this.http.get<T>(url, { params }).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }

  post(endpoint: string, body: any, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;

    return this.http.post<any>(url, body, { headers }).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }

  put(endpoint: string, body: any, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;

    return this.http.put<any>(url, body, { headers }).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }

  delete(endpoint: string, params?: HttpParams): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;

    return this.http.delete<any>(url, { params }).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }
}
