export interface Achieve {
    id?: string;
    type?: number;
    title: string;
    description: string;
    user: string;
    achieveImageUrl?: string;
    achieveStatus?: number;
    visibility?: number;
    follower?: string;
    tagAchieve?: string;
    tagExplore?: string;
    achieveProgress?: number;
    encourage?: Encourage[];
    team?: Team[]
    discuss?: Discuss[];
    learn?: Learn[];
    crowdSourceJob?: CrowdSourceJob[];
    crowdSourceLoan?: CrowdSourceLoan[];
    crowdSourceDonation?: CrowdSourceDonation[];
    celebrate?: Celebrate[];
    plan?: Plan[];
    view?:any[];
    copied?:any[];
    priority?:boolean;
}

export interface AchieveHeader {
    id?: string;
    type?: number;
    title: string;
    description?: string;
    user: string;
    achieveImageUrl?: string;
    achieveStatus?: number;
    visibility?: number;
    follower?: string;
    tagAchieve?: string;
    tagExplore?: string;
    selected?: boolean;
    achieveProgress?: number;
    priority?:boolean;
    userName?:string;
    userProfileImage?:string;
}

export interface AchieveCradsDTO{
    highPriorityCards:AchieveHeader[];
    lowPriorityCards:AchieveHeader[];
    completedCards:AchieveHeader[];
    teamCards:AchieveHeader[];
}

export interface Plan {
    id?: string;
    planId?:string;
    achieveId: string;
    activity: string;
    status?: boolean;
    user?: string;
    userProfileImage?: string;
}

export interface Discuss {
    id?: string;
    achieveId: string;
    achieveTitle?:string;
    achieveType?:number;
    achieveImageUrl?:string;
    achieveVisibility?:number;
    postHeader: string;
    user: string;
    userCountry?:string;
    userName?:string;
    userProfileImage?:string;
    created?:string;
    commentsData?: CommentsData[];
    view?:any[];
}

export interface Learn {
    id: string;
    achieveId: string;
    activity: string;
    status: string;
    user: string
    title: string;
    url: string;
    image: string;
    useful: any[];
    notuseful: any[];
    category?:string;
}

export interface CrowdSourceJob {
    id?: string;
    achieveId: string;
    achieveTitle?:string;
    achieveType?:number;
    achieveImageUrl?:string;
    achieveVisibility?:number;
    describeScope: string;
    payMoney: string;
    user: string;
    userName?:string;
    userProfileImage?:string;
    created?: string;
    commentsData?: CommentsData[];
    view?:any[];
    userCountry?:string;
}

export interface CrowdSourceLoan {
    id?: string;
    achieveId: string;
    achieveTitle?:string;
    achieveType?:number;
    achieveImageUrl?:string;
    achieveVisibility?:number;
    describeScope: string;
    askMoney: string;
    raisedMoney:string;
    user: string;
    userName?:string;
    userProfileImage?:string;
    created?: string;
    commentsData?: CommentsData[];
    view?:any[];
    userCountry?:string;
}

export interface CrowdSourceDonation {
    id?: string;
    achieveId: string;
    achieveTitle?:string;
    achieveType?:number;
    achieveImageUrl?:string;
    achieveVisibility?:number;
    describeScope: string;
    askMoney: string;
    raisedMoney:string;
    user: string;
    userName?:string;
    userProfileImage?:string;
    userCountry?:string;
    created?: string;
    commentsData?: CommentsData[];
    view?:any[];
}

export enum AchieveType {
    Plan = 1,
    Learn = 2,
    Team = 3,
    LinkedAchieve = 4,
    Encourage = 5,
    Discuss = 6,
    CrowdSurceLoan = 7,
    CrowdSourceJob = 8,
    CrowdSourceDonation = 9,
    Celebrate = 9
}

export interface CommentsData {
    id?: string;
    achieveId:string;
    discussId?:string;
    crowdSourceJobId?:string;
    crowdSourceLoanId?:string;
    crowdSourceDonationId?:string;
    commentText: string;
    user: string;
    userName?:string;
    userProfileImage?:string;
    view?:any[];
    useful?:any[];
    notUseful?:any[];
}

export interface Celebrate {
    id?:string;
    achieveId: string;
    celebrateImageUrl: string;
    created?: string;
    userName?:string;
}

export interface Team {
    id?:string;
    achieveId: string;
    user?:string;
    role?:string;
    userName?:string;
    userProfileImage?:string;
}

export interface Encourage {
    id?:string;
    achieveId: string;
    user?:string;
    count?:number;
    userName?:string;
    userProfileImage?:string;
}