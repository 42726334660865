<div class="container pt-4">
    <div class="flex flex-row justify-between">
        <div class="flex flex-row align-items-center justify-between w-full">
            <div class="px-3">
                <h2 mat-dialog-title class="close-modal-icon mb-0 ps-0 fw-600 text-muted-8">{{achieveHeader?.title}}</h2>
            </div>
            <mat-icon mat-dialog-close (click)="onCancel()" class="close-modal-icon text-primary cursor-pointer mx-1">close</mat-icon>
          
        </div>
    </div>
    <div class="cover p-2" id="cover">
     <!--    <div class="flex flex-row gap-2">
            <div class="w-[40%]">
                <img src="{{achieveImage}}" class="achieve_bg_image">
            </div>
            <div class="w-[60%]">
                <div class="flex flex-row gap-2">
                    <div class="w-[50%]">
                        <div class="flex items-center justify-between">
                            <h4>Team</h4>
                            <span>Add Member</span>
                        </div>
                        <div class="flex items-center justify-between">
                            <h4>Progress</h4>
                            <div class="w-50">
                                <mat-progress-bar mode="determinate" class="custom-progress-bar"
                                    [value]="achieveHeader?.achieveProgress"></mat-progress-bar>
                            </div>
                        </div>
                    </div>
                    <div class="w-[50%] border-le pl-3">
                        <div class="flex items-center justify-between">
                            <h4>Visibility</h4>
                            <span>{{achieveVisibility[achieveHeader?.visibility]}}</span>
                        </div>
                        <div class="flex items-center justify-between">
                            <h4>Card Type</h4>
                            <span>{{achieveCardType[achieveHeader?.type]}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="wrapper-panel">
            <div class="item-panel mt-2">
                <div class="profile position-relative">
                  <img src="{{achieveImage}}"
                   class="section-img">   
                </div>
            </div>
            <div class="item-panel mt-2">
            <mat-card class="achieve-detail-card">      
              <div class="d-flex mt-3 align-items-center " >
                <div class="width-50">
                  <!-- <h4 class="mb-0 fw-500">Team:</h4> -->
                </div>
                <div class="width-50">
                  <!-- <a class="underline text-muted-8">
                     Add Member</a> -->
                </div>
              </div>
        
              <div class="d-flex mt-3 align-items-center " >
                <div class="width-50">
                  <h4 class="mb-0 fw-500">Progress:</h4>
                </div>
                <div class="width-50">
                  <mat-progress-bar mode="determinate" class="custom-progress-bar"
                  [value]="achieveHeader?.achieveProgress"></mat-progress-bar>
                </div>
              </div>
        
              <div class="d-flex mt-3 align-items-center " >
                <div class="width-50">
                  <h4 class="mb-0 fw-500">Visibility:</h4>
                </div>
                <div class="width-50">
                  {{achieveVisibility[achieveHeader?.visibility]}}
                </div>
              </div>
        
              <div class="d-flex mt-3 align-items-center " >
                <div class="width-50">
                  <h4 class="mb-0 fw-500">Card Type:</h4>
                </div>
                <div class="width-50">
                  {{achieveCardType[achieveHeader?.type]}}
                </div>
              </div>
        
            
        
            </mat-card>
            </div>
        </div>

    </div>
    <form>
        <div class="row mt-3">
            <div class="col-md-12">
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fa-regular fa-calendar me-2"></i>
                            Plan
                        </ng-template>
                        <table mat-table *ngIf="dataSourcePlan.length > 0" [dataSource]="dataSourcePlan"
                            class="mat-elevation-z8">
                            <ng-container matColumnDef="activity">
                                <th mat-header-cell *matHeaderCellDef> Title </th>
                                <td mat-cell *matCellDef="let element"> {{element.activity}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fa-solid fa-pen me-2"></i>
                            Learn
                        </ng-template>

                        <div class="my-3 mx-m-3">
                            <mat-card class="general-card p-m-3" *ngFor="let learn of learnByCategory">
                                <p class="text-2xl">{{learn.category}}</p>
                                <mat-card-content class="cursor-pointer">
                                    <div *ngFor="let item of learn.Values">
                                        <div class="flex flex-row justify-between">
                                            <div>
                                                {{item.title}}
                                            </div>
                                            <div class="d-flex">
                
                                                <a class="flex flex-auto py-4 me-2 hover:bg-hover"
                                                >
                                                <i class="fa-regular fa-thumbs-up text-primary-300" 
                                                [ngClass]="{'fa-solid': item.useful?.length > 0}" ></i> <sup>{{item.useful?.length}}</sup>  
                                            </a>
                                            <a class="flex flex-auto py-4 hover:bg-hover"
                                            >
                                            <i class="fa-regular fa-thumbs-down text-primary-300"
                                            [ngClass]="{'fa-solid': item.notUseful?.length > 0}"
                                            ></i> <sup>{{item.notUseful?.length}}</sup>  
                                        </a>
                                    </div>
                                           <!--  <div>
                                                <i
                                                    class="fa-regular fa-thumbs-up"></i><sup>{{item.useful?.length}}</sup>
                                                <i
                                                    class="fa-regular fa-thumbs-down ms-2"></i><sup>{{item.notuseful?.length}}</sup>
                                            </div> -->
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <i class="fa-solid fa-pen me-2"></i>
                            Celebrate
                        </ng-template>
                        <div class="container-fluid">
                            <div class="row mt-2"
                                *ngIf="dataSourceCelebrate?.length <= 0  && dataSourceCelebrate == null">
                                <div class="col-md-12 text-center">
                                    <h5>No photo found</h5>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="dataSourceCelebrate?.length > 0">
                              <!--   <div class="col-md-4" *ngFor="let celebrate of dataSourceCelebrate">
                                    <img [src]="getImage(celebrate.celebrateImageUrl)">
                                </div> -->
                                <div class="col-xl-3 col-md-4  mb-3 position-relative" *ngFor="let celebrate of dataSourceCelebrate">
                                    <img class="celebrate-img" [src]="getImage(celebrate.celebrateImageUrl)">
                                   
                                  </div>
                            </div>
                        </div>

                    </mat-tab>

                </mat-tab-group>
            </div>
        </div>
    </form>
</div>