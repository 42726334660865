import { Component } from '@angular/core';

@Component({
  selector: 'owx-dots-loading-spinner',
  templateUrl: './dots-loading-spinner.component.html',
  styleUrl: './dots-loading-spinner.component.scss'
})
export class DotsLoadingSpinnerComponent {

}
