import { Injectable } from "@angular/core";
import { isEmpty } from "lodash";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { AchieveSectionApiService } from "../../../../shared/services/generics/achieve-section-api.service";
import { AchieveHeader, Plan, Learn, Discuss, CommentsData, CrowdSourceJob, CrowdSourceLoan, CrowdSourceDonation, Celebrate, Team, Encourage } from "../models/achieve";
import { blobKey } from "app/contants";

@Injectable({
    providedIn: 'root',
})
export class AchieveSectionDataService extends AchieveSectionApiService {
    private _achieveHeaders: BehaviorSubject<AchieveHeader[]> = new BehaviorSubject<AchieveHeader[]>(null);

    get achieveHeaders$(): Observable<AchieveHeader[]> {
        return this._achieveHeaders.asObservable();
    }

    getAchieveHeaderById(id?: string): Observable<AchieveHeader> {
        return this.get<AchieveHeader>("Header/Get/" + id);
    }

    getAchieveHeader(userId?: any): Observable<AchieveHeader[]> {
        return this.get<AchieveHeader[]>("Header/GetHeaderByUser/" + userId).pipe(
            tap((res: AchieveHeader[]) => {
                if (isEmpty(res)) {
                    this._achieveHeaders.next(res ?? []);
                    return res ?? [];
                }

                res?.forEach(element => {
                    element.selected = false;
                    element.achieveImageUrl = element.achieveImageUrl + blobKey;
                });
                this._achieveHeaders.next(res);
                return res;
            })
        );
    }

    createAchieveHeader(achieveHeader: AchieveHeader): Observable<any> {
        return this.post("Header/Create", achieveHeader);
    }

    updateAchieveHeader(achieveHeader: AchieveHeader): Observable<any> {
        return this.put("Header/Update", achieveHeader);
    }

    updateAchievePlan(plan: Plan): Observable<any> {
        return this.put("Plan/update", plan);
    }

    updateAchievePlans(plan: Plan[]): Observable<any> {
        return this.put("Plan/update-plans", plan);
    }

    createAchievePlan(plan: Plan): Observable<any> {
        return this.post("Plan/create", plan);
    }

    deleteAchievePlan(achieveId: string, planId: string): Observable<any> {
        return this.delete("Plan/delete/" + achieveId + "/" + planId);
    }

    getAchievePlans(achieveId: string): Observable<any> {
        return this.get("Plan/GetAll/" + achieveId);
    }

    //learn

    updateAchieveLearn(learn: Learn): Observable<any> {
        return this.put("Learn/update", learn);
    }

    toggleAchieveLearnUsefulness(learn:Learn): Observable<any> {
        return this.post("Learn/update-learn-usefulness", learn);
    }

    createAchieveLearn(learn: Learn): Observable<any> {
        return this.post("Learn/create", learn);
    }

    deleteAchieveLearn(achieveId: string, learnId: string): Observable<any> {
        return this.delete("Learn/delete/" + achieveId + "/" + learnId);
    }

    getAchieveLearns(achieveId: string): Observable<any> {
        return this.get("Learn/GetAll/" + achieveId);
    }

    getLearnsByCategory(achieveId: string): Observable<any> {
        return this.get("Learn/get-by-task-category/" + achieveId);
    }

    //discuss
    updateAchieveDiscuss(discuss: Discuss): Observable<any> {
        return this.put("Discuss/update", discuss);
    }

    createAchieveDiscuss(discuss: Discuss): Observable<any> {
        return this.post("Discuss/create", discuss);
    }

    deleteAchieveDiscuss(achieveId: string, discussId: string): Observable<any> {
        return this.delete("Discuss/delete/" + achieveId + "/" + discussId);
    }

    getAchieveDiscusss(achieveId: string): Observable<any> {
        return this.get("Discuss/GetAll/" + achieveId);
    }

    getAchieveDiscusssById(achieveId: string, discussId: string): Observable<any> {
        return this.get("Discuss/Get/" + achieveId + "/" + discussId);
    }

    getAchieveAllDiscusss(filter?): Observable<any> {
        return this.post("Discuss/GetAll",filter);
    }
    
    deleteDiscussComment(achieveId, discussId, commentId): Observable<any> {
        return this.delete("Discuss/Comment/Delete/" + achieveId + "/" + discussId + "/" + commentId);
    }

    updateDiscussComment(comment): Observable<any> {
        return this.put("Discuss/Comment/Update",comment);
    }

    //discuss comment
    updateAchieveDiscussComment(comment: CommentsData): Observable<any> {
        return this.put("Discuss/Comment/update", comment);
    }

    createAchieveDiscussComment(comment: CommentsData): Observable<any> {
        return this.post("Discuss/Comment/Create", comment);
    }

    deleteAchieveDiscussComment(achieveId: string, discussId: string, commentId: string): Observable<any> {
        return this.delete("Discuss/Comment/Delete/" + achieveId + "/" + discussId + "/" + commentId);
    }

    updateAchieveDiscussViews(data): Observable<any> {
        return this.post("Discuss/update-view", data);
    }

    toggleAchieveDiscussCommentStatus(commentStatus): Observable<any> {
        return this.post("Discuss/Comment/update-comment-status", commentStatus);
    }

    //crowdsource job
    updateAchieveCrowSourceJob(crowdSourceJob: CrowdSourceJob): Observable<any> {
        return this.put("CrowdsourceJob/update", crowdSourceJob);
    }

    createAchieveCrowSourceJob(crowdSourceJob: CrowdSourceJob): Observable<any> {
        return this.post("CrowdsourceJob/create", crowdSourceJob);
    }

    deleteAchieveCrowSourceJob(achieveId: string, crowdSourceJobId: string): Observable<any> {
        return this.delete("CrowdsourceJob/delete/" + achieveId + "/" + crowdSourceJobId);
    }

    getAchieveAllCrowSourceJobs(filter?): Observable<any> {
        return this.post("CrowdsourceJob/GetAll", filter);
    }

    getAchieveCrowSourceJobById(achieveId: string, crowdsourceJobId: string): Observable<any> {
        return this.get("CrowdsourceJob/Get/" + achieveId + "/" + crowdsourceJobId);
    }

    getAchieveCrowSourceJobs(achieveId: string): Observable<any> {
        return this.get("CrowdsourceJob/GetAll/" + achieveId);
    }

    toggleAchieveCrowSourceJobCommentStatus(commentStatus): Observable<any> {
        return this.post("CrowdsourceJob/Comment/update-comment-status", commentStatus);
    }

    updateAchieveCrowSourceJobViews(data): Observable<any> {
        return this.post("CrowdsourceJob/update-view", data);
    }

    //crowdsource job comment
    updateAchieveCrowSourceJobComment(comment: CommentsData): Observable<any> {
        return this.put("CrowdsourceJob/Comment/update", comment);
    }

    createAchieveCrowSourceJobComment(comment: CommentsData): Observable<any> {
        return this.post("CrowdsourceJob/Comment/Create", comment);
    }

    deleteAchieveCrowSourceJobComment(achieveId: string, crowdSourceJobId: string, commentId: string): Observable<any> {
        return this.delete("CrowdsourceJob/Comment/Delete/" + achieveId + "/" + crowdSourceJobId + "/" + commentId);
    }

    //crowdsource loan
    updateAchieveCrowSourceLoan(crowdSourceLoan: CrowdSourceLoan): Observable<any> {
        return this.put("CrowdsourceLoan/update", crowdSourceLoan);
    }

    createAchieveCrowSourceLoan(crowdSourceLoan: CrowdSourceLoan): Observable<any> {
        return this.post("CrowdsourceLoan/Create", crowdSourceLoan);
    }

    deleteAchieveCrowSourceLoan(achieveId: string, crowdSourceLoanId: string): Observable<any> {
        return this.delete("CrowdsourceLoan/delete/" + achieveId + "/" + crowdSourceLoanId);
    }

    getAchieveAllCrowSourceLoans(filter?): Observable<any> {
        return this.post("CrowdsourceLoan/GetAll", filter);
    }

    getAchieveCrowSourceLoanById(achieveId: string, crowdsourceLoanId: string): Observable<any> {
        return this.get("CrowdsourceLoan/Get/" + achieveId + "/" + crowdsourceLoanId);
    }

    getAchieveCrowSourceLoans(achieveId: string): Observable<any> {
        return this.get("CrowdsourceLoan/GetAll/" + achieveId);
    }

    toggleAchieveCrowSourceLoanCommentStatus(commentStatus): Observable<any> {
        return this.post("CrowdsourceLoan/Comment/update-comment-status", commentStatus);
    }

    updateAchieveCrowSourceLoanViews(data): Observable<any> {
        return this.post("CrowdsourceLoan/update-view", data);
    }

    //crowdsource loan comment
    updateAchieveCrowSourceLoanComment(comment: CommentsData): Observable<any> {
        return this.put("CrowdsourceLoan/Comment/update", comment);
    }

    createAchieveCrowSourceLoanComment(comment: CommentsData): Observable<any> {
        return this.post("CrowdsourceLoan/Comment/Create", comment);
    }

    deleteAchieveCrowSourceLoanComment(achieveId: string, crowdSourceLoanId: string, commentId: string): Observable<any> {
        return this.delete("CrowdsourceLoan/Comment/Delete/" + achieveId + "/" + crowdSourceLoanId + "/" + commentId);
    }

    //crowdsource donation
    updateAchieveCrowSourceDonation(crowdSourceDonation: CrowdSourceDonation): Observable<any> {
        return this.put("CrowdsourceDonation/update", crowdSourceDonation);
    }

    createAchieveCrowSourceDonation(crowdSourceDonation: CrowdSourceDonation): Observable<any> {
        return this.post("CrowdsourceDonation/Create", crowdSourceDonation);
    }

    deleteAchieveCrowSourceDonation(achieveId: string, crowdSourceDonationId: string): Observable<any> {
        return this.delete("CrowdsourceDonation/delete/" + achieveId + "/" + crowdSourceDonationId);
    }

    getAchieveAllCrowSourceDonations(filter?): Observable<any> {
        return this.post("CrowdsourceDonation/GetAll", filter);
    }

    getAchieveCrowSourceDonationById(achieveId: string, crowdsourceDonationId: string): Observable<any> {
        return this.get("CrowdsourceDonation/Get/" + achieveId + "/" + crowdsourceDonationId);
    }

    getAchieveCrowSourceDonations(achieveId: string): Observable<any> {
        return this.get("CrowdsourceDonation/GetAll/" + achieveId);
    }

    //crowdsource donation comment
    updateAchieveCrowSourceDonationComment(comment: CommentsData): Observable<any> {
        return this.put("CrowdsourceDonation/Comment/update", comment);
    }

    createAchieveCrowSourceDonationComment(comment: CommentsData): Observable<any> {
        return this.post("CrowdsourceDonation/Comment/Create", comment);
    }

    deleteAchievCrowSourceDonationCeomment(achieveId: string, crowdSourceDonationId: string, commentId: string): Observable<any> {
        return this.delete("CrowdsourceDonation/Comment/Delete/" + achieveId + "/" + crowdSourceDonationId + "/" + commentId);
    }

    toggleAchieveCrowSourceDonationCommentStatus(commentStatus): Observable<any> {
        return this.post("CrowdsourceDonation/Comment/update-comment-status", commentStatus);
    }

    updateAchieveCrowSourceDonationViews(data): Observable<any> {
        return this.post("CrowdsourceDonation/update-view", data);
    }

    //celebrate
    updateAchieveCelebrate(celebrate: Celebrate): Observable<any> {
        return this.put("Celebrate/update", celebrate);
    }

    createAchieveCelebrate(celebrate: Celebrate): Observable<any> {
        return this.post("Celebrate/create", celebrate);
    }

    deleteAchieveCelebrate(achieveId: string, CelebrateId: string): Observable<any> {
        return this.delete("Celebrate/delete/" + achieveId + "/" + CelebrateId);
    }

    getAchieveCelebrates(achieveId: string): Observable<any> {
        return this.get("Celebrate/GetAll/" + achieveId);
    }

    //team
    updateAchieveTeam(team: Team): Observable<any> {
        return this.put("team/update", team);
    }

    createAchieveTeam(team: Team): Observable<any> {
        return this.post("Team/create", team);
    }

    deleteAchieveTeam(achieveId: string, teamId: string): Observable<any> {
        return this.delete("Team/delete/" + achieveId + "/" + teamId);
    }

    getAchieveTeams(achieveId: string): Observable<any> {
        return this.get("Team/GetAll/" + achieveId);
    }

    //encourage
    updateAchieveEncourage(encourage: Encourage): Observable<any> {
        return this.put("Encourage/update", encourage);
    }

    createAchieveEncourage(encourage: Encourage): Observable<any> {
        return this.post("Encourage/create", encourage);
    }

    deleteAchieveEncourage(achieveId: string, encourageId: string): Observable<any> {
        return this.delete("Encourage/delete/" + achieveId + "/" + encourageId);
    }

    getAchieveEncourages(achieveId: string): Observable<any> {
        return this.get("Encourage/GetAll/" + achieveId);
    }
}