<div class="d-flex justify-between align-items-center">
  <h2 mat-dialog-title class="mb-0 fw-600 text-muted-8">Update</h2>
  <mat-icon mat-dialog-close class="close-modal-icon text-primary cursor-pointer">close</mat-icon>
</div>

<mat-dialog-content >
  <form [formGroup]="updateListForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" style="width: 100%;" [floatLabel]="'always'" >
          <mat-label>Select</mat-label>
          <mat-select formControlName="trueSelfTemplateType">
            <mat-option value="1">Global Principle</mat-option>
            <mat-option value="2">Personality Develop</mat-option>
            <mat-option value="3">Healthy Habit</mat-option>
            <mat-option value="4">Thinking</mat-option>
            <mat-option value="5">Skill</mat-option>
          </mat-select>
          <mat-error *ngIf="updateListForm.get('trueSelfTemplateType').hasError('required')">
            Please select an option
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="fill" style="width: 100%;">
          <input matInput placeholder="Enter a brief detail" formControlName="name">
          <mat-error *ngIf="updateListForm.get('name').hasError('required')">
            Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-start">
          <button type="submit" mat-button color="primary" 
            class="primary-mat-btn mat-icon-btn">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
 
</mat-dialog-content>

