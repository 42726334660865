<div class="d-flex justify-between align-items-center">
  <h2 mat-dialog-title class="mb-0 fw-600 text-muted-8">Delete Item</h2>
  <mat-icon mat-dialog-close class="text-primary cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="p-4">
  <div class="container">
    <div class="row mb-4">
      <div class="col-md-12">
        <div>
          Would you like to delete?
        </div>      
      </div>
    </div>
    <div mat-dialog-actions class="justify-content-end">
      <button mat-button  class="btn btn-secondary px-4" mat-dialog-close>No</button>
      <button mat-button color="primary" class="primary-mat-btn mat-icon-btn" (click)="confirm()">Yes</button>
    </div>
  </div>

</mat-dialog-content>